import { authAxios as axios } from "shared-auth";
import config from "config/global";
import {
  IUserInformation,
  IAccountSettingsLoginDetailsProps,
  IAccountSettingsLoginDetailsSecurityQ,
} from "models/page";

class UserAccountService {
  private url: string;

  constructor({ url }: { url: string }) {
    this.url = url;
  }

  public async getUserInfo(): Promise<undefined | IUserInformation[]> {
    const { data } = await axios.get<IUserInformation[]>(`${this.url}/account`);
    return data;
  }

  public async redirectToDiscountPage(): Promise<string | undefined> {
    const { data } = await axios.get(`${this.url}/discount`);
    return data;
  }

  public async getUserDetailsForSettingsPage(): Promise<
    undefined | IAccountSettingsLoginDetailsProps
  > {
    const { data } = await axios.get<IAccountSettingsLoginDetailsProps>(
      `${this.url}/settings/user`
    );
    return data;
  }

  public async getUserSecurityQForSettingsPage(): Promise<
    undefined | IAccountSettingsLoginDetailsSecurityQ
  > {
    const { data } = await axios.get<IAccountSettingsLoginDetailsSecurityQ>(
      `${this.url}/settings/security-questions/current`
    );

    return data;
  }

  public async getSecurityQuestions() {
    const { data } = await axios.get<string[]>(
      `${this.url}/settings/security-questions`
    );

    return data;
  }

  public async updateUserSecurityQuestion(questionAndAnswer: {
    question: string;
    answer: string;
  }) {
    const { data } = await axios.post(
      `${this.url}/settings/security-questions/save`,
      questionAndAnswer
    );

    return data;
  }

  public async updateUserPassword(password: string) {
    const { data } = await axios.put(`${this.url}/settings/password`, {
      password,
    });

    return data;
  }

  public async updateUserEmail(email: string) {
    const { data } = await axios.put(`${this.url}/settings/email`, {
      email,
    });

    return data;
  }

  public async resendEmailVerification(emailAddress: string) {
    const { data } = await axios.post(
      `${this.url}/settings/email/verify/resend`,
      { emailAddress }
    );

    return data;
  }

  public async cancelEmailVerification(emailAddress: string) {
    const { data } = await axios.post(
      `${this.url}/settings/email/verify/cancel`,
      { emailAddress }
    );

    return data;
  }
}

const apiUrl = (config.userAccountApiUrl || "").replace(/\/$/, "");

if (apiUrl.trim().length == 0) {
  throw new Error("Invalid userAccountApiUrl.");
}

export default new UserAccountService({
  url: apiUrl,
});
