import { CheckoutFlow } from "../types";

export interface IEndpointsPreset {
  subscriptions: string;
  summary: string;
  preview: string;
  purchase: string;
  existingPaymentMethod: string;
}

// TODO in SL-5387: utilise isV2 and add those new endpoints
export const getDefaultEndpointsPreset = (url: string) => ({
  subscriptions: `${url}plans/renewal`,
  summary: `${url}account`,
  preview: `${url}checkout/preview`,
  purchase: `${url}checkout/purchase`,
  existingPaymentMethod: `${url}account/activepaymentmethod`,
});

export const getJoiningEndpointsPreset = (url: string) => ({
  subscriptions: `${url}plans/joining`,
  summary: `${url}account/summary`,
  preview: `${url}joining/preview`,
  purchase: `${url}account/billing`,
  existingPaymentMethod: "",
});

export const getSubscriptionEndpointsPreset = (url: string) => ({
  subscriptions: `${url}plans/upgrade`,
  summary: `${url}account`,
  preview: `${url}subscription/preview`,
  purchase: `${url}account/billing-info`,
  existingPaymentMethod: `${url}account/activepaymentmethod`,
});

export const endpointsPresets = {
  [CheckoutFlow.Default]: getDefaultEndpointsPreset,
  [CheckoutFlow.Joining]: getJoiningEndpointsPreset,
  [CheckoutFlow.AccountSettings]: getSubscriptionEndpointsPreset,
  [CheckoutFlow.AddOnBundle]: getDefaultEndpointsPreset,
};
