import React, { FC } from "react";
import { useTranslation } from "i18n";
import {
  AlertMessage,
  IAlertMessageProps,
  IPopupProps,
  Popup,
  TFunction,
} from "../../../index";

export const KNOWN_ERROR_STATUS_CODES = [400, 401, 403, 404, 500, 503, 504];
const GENERIC_ERROR_CODE = "generic";

const defaultPopupProps: Omit<IPopupProps, "texts"> = {
  width: { lg: 5, md: 6 },
  close: () => {},
  priority: "high",
};

const defaultAlertMessageProps: Omit<IAlertMessageProps, "texts"> = {
  icon: "notice",
  buttons: [
    {
      name: "Close",
      type: "primary",
      click: () => {},
    },
  ],
};

const getStatusCodeForTranslation = (
  statusCode: number | undefined
): string => {
  if (!statusCode) return GENERIC_ERROR_CODE;
  if (KNOWN_ERROR_STATUS_CODES.includes(statusCode))
    return statusCode.toString();

  return GENERIC_ERROR_CODE;
};

export const getAlertMessageTexts = (
  t: TFunction,
  errorStatusCode?: number
) => {
  const i18nErrorStatusCode = getStatusCodeForTranslation(errorStatusCode);

  return {
    title: t(`common:errorPopup.httpStatus.${i18nErrorStatusCode}.title`),
    description: t(`common:errorPopup.httpStatus.${i18nErrorStatusCode}.body`),
  };
};

type CommonErrorPopupProps = {
  popupProps?: Partial<IPopupProps>;
  alertMessageProps?: Partial<IAlertMessageProps>;
  errorStatusCode: number | undefined;
};

const CommonErrorPopup: FC<CommonErrorPopupProps> = ({
  popupProps = defaultPopupProps,
  alertMessageProps = defaultAlertMessageProps,
  errorStatusCode,
}) => {
  const { t } = useTranslation();

  return (
    <Popup
      width={popupProps.width || defaultPopupProps.width}
      close={popupProps.close || defaultPopupProps.close}
      priority={popupProps.priority || defaultPopupProps.priority}
      texts={{ closePopup: t("common:button.label.close") }}
    >
      <AlertMessage
        icon={alertMessageProps.icon || defaultAlertMessageProps.icon}
        texts={getAlertMessageTexts(t, errorStatusCode)}
        buttons={alertMessageProps.buttons || defaultAlertMessageProps.buttons}
      />
    </Popup>
  );
};

export default CommonErrorPopup;
