import React, { useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "i18n";
import {
  IBillingApiResponse,
  applyFilmmakersTrialCoupon,
} from "services/api/BillingApi/BillingApi";
import checkIsNoActiveSubscription from "helpers/checkIsNoActiveSubscription";
import FilmmakersTrialConfirmPopup from "./FilmmakersTrialConfirmPopup";
import NotificationBanner from "../NotificationBanner";

const FM_BANNER_SHOWN_KEY = "fm-b-shown";
const hasUserOptedOutPreviously = JSON.parse(
  localStorage.getItem(FM_BANNER_SHOWN_KEY) ?? "false"
);

const FilmmakerTrialBanner = ({
  performerSubscriptionData,
  isLoading,
}: {
  performerSubscriptionData: IBillingApiResponse | undefined;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const [isTrialNotificationBannerHidden, setIsTrialNotificationBannerHidden] =
    useState(hasUserOptedOutPreviously);
  const [fmConfirmPopupOpen, setFmConfirmPopupOpen] = useState(false);
  const { mutate: applyFMCouponMutation, isLoading: isApplyFMCouponLoading } =
    useMutation({
      mutationFn: applyFilmmakersTrialCoupon,
      onSuccess: (data) => {
        if (data && data?.status >= 500 && data?.status < 600) return;

        localStorage.setItem(FM_BANNER_SHOWN_KEY, "true");
        setFmConfirmPopupOpen(false);
        setIsTrialNotificationBannerHidden(true);
      },
    });
  const { account, subscription } = performerSubscriptionData ?? {};
  const noActiveSubscription = checkIsNoActiveSubscription({
    id: account?.id,
    subscription: subscription,
  });

  const doNotRenderBanner =
    !account?.id ||
    !subscription ||
    noActiveSubscription ||
    isLoading ||
    isTrialNotificationBannerHidden;

  if (doNotRenderBanner) {
    return null;
  }

  const handleTrialOptOut = () => {
    localStorage.setItem(FM_BANNER_SHOWN_KEY, "true");
    setIsTrialNotificationBannerHidden(true);
  };

  return (
    <>
      <NotificationBanner
        withButtons
        wrapperClassNames="bg-turquoise-10"
        messageHtml={t("performer:home.filmmakersTrial.banner.message")}
        buttons={[
          {
            title: t("common:button.label.dismiss"),
            href: "##",
            text: t("common:button.label.dismiss"),
            type: "secondary",
            onClick: handleTrialOptOut,
          },
          {
            title: t("common:button.label.banner.startFreeTrial"),
            href: "#",
            text: t("common:button.label.banner.startFreeTrial"),
            onClick: () => {
              setFmConfirmPopupOpen(true);
            },
          },
        ]}
      />
      {fmConfirmPopupOpen && (
        <FilmmakersTrialConfirmPopup
          setFmConfirmPopupOpen={setFmConfirmPopupOpen}
          handleTrialOptOut={handleTrialOptOut}
          applyFMCouponMutation={applyFMCouponMutation}
          isApplyFMCouponLoading={isApplyFMCouponLoading}
        />
      )}
    </>
  );
};

export default FilmmakerTrialBanner;
