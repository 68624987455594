import React, { FC, useCallback, useContext, useState } from "react";
import { useTranslation } from "i18n";
import { Input } from "../../../../Molecules/Input/Input";
import { Button } from "../../../../Atoms/Button/Button";
import "./styles.scss";
import {
  getTootlipProps,
  QuestionTooltip,
} from "../../../../Molecules/QuestionTooltip";
import { useBillingTranslations } from "../../hooks/useBillingTranslations";
import { BillingUser } from "translations/src/models/billing";
import { ISubscription, ISummary, ICouponObject } from "../../types";
import ShowMoreLessButton from "../../../../Atoms/ShowMoreLessButton";
import DiscountCouponLEGACY from "./DiscountCouponLEGACY";
import {
  DISABILITY_DISCOUNT_CODE_ACC_LEVEL,
  MAX_COUPONS_ALLOWED_WITH_ACC_LEVEL,
} from "../../constants";
import { BillingContext } from "../../BillingContext";
interface Props
  extends Omit<ISummary, "firstName" | "lastName" | "discounts">,
    Pick<ISubscription, "total" | "amount"> {
  name: string;
  planName: string;
  vatLabel: string;
  isMobileView: boolean | undefined;
  hasAccountDiscount?: boolean;
  vatAmount: string;
  discountAmount?: string;
  creditApplied?: string;
  accountCredited?: string;
  userCouponCodesObject: ICouponObject[];
  couponErrors: string[];
  setCoupon: (coupon: string) => Promise<void>;
  removeCoupon: (coupon: string) => Promise<void>;
  isCheckoutPreviewRequestLoading: boolean;
}
const commonTextStyles = "text-m md:text-[18px] leading-[27px]";
const OrderSummaryLEGACY: FC<Props> = ({
  name,
  email,
  phoneNumber,
  planName,
  total,
  amount,
  discountAmount,
  creditApplied,
  accountCredited,
  vatLabel,
  vatAmount,
  isMobileView,
  userCouponCodesObject,
  couponErrors,
  setCoupon,
  removeCoupon,
  hasAccountDiscount,
  isCheckoutPreviewRequestLoading,
}) => {
  const { disableDiscountCodeField } = useContext(BillingContext);
  const { t } = useTranslation();
  const [isShowMoreExpanded, setIsShowMoreExpanded] = useState(false);
  const shouldShowSection =
    !isMobileView || (isMobileView && isShowMoreExpanded);
  const {
    texts: { summary, common },
  } = useBillingTranslations(BillingUser.Common);
  const [discountCodeValue, setDiscountCodeValue] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const loadingToggle = () => setLoading((isLoading) => !isLoading);
  const handleDiscountCodeChange = useCallback(
    (val: string) => {
      setDiscountCodeValue(val);
    },
    [setDiscountCodeValue]
  );
  const handleAddDiscountCode = useCallback(async () => {
    if (!discountCodeValue || loading) return;
    loadingToggle();
    await setCoupon(discountCodeValue.trim());
    setDiscountCodeValue("");
    loadingToggle();
  }, [
    discountCodeValue,
    loading,
    setCoupon,
    setDiscountCodeValue,
    loadingToggle,
  ]);
  const handleRemoveDiscountCode = useCallback(
    async (coupon: string) => {
      loadingToggle();
      await removeCoupon(coupon);
      loadingToggle();
    },
    [removeCoupon, loadingToggle]
  );
  const showMore = useCallback(() => setIsShowMoreExpanded(true), []);
  const couponAreaDisableState = hasAccountDiscount
    ? userCouponCodesObject.length === MAX_COUPONS_ALLOWED_WITH_ACC_LEVEL
    : userCouponCodesObject.length === 1;

  return (
    <div
      className={`${
        isMobileView ? "p-[10px]" : "p-5"
      } bg-grey-98 rounded-[10px] w-auto sticky top-0`}
    >
      <h2 className="font-semibold text-lg md:text-[26px] md:leading-[39px]">
        {summary.summaryTitle}
      </h2>
      <hr className="my-5" />
      {/* Customer details */}
      {shouldShowSection && (
        <>
          <div>
            <div className="flex items-baseline justify-between">
              <h3 className="subheaders">
                <div>{summary.customerDetailTitle}</div>
                <QuestionTooltip
                  tooltipProps={getTootlipProps(
                    summary.customerDetailTooltipHeader,
                    summary.customerDetailTooltipBody
                  )}
                />
              </h3>
            </div>
            <div className={`flex mb-2.5 ${commonTextStyles}`}>
              <span className="mr-1 font-semibold">{summary.nameLabel}</span>
              <p className={commonTextStyles}>{name}</p>
            </div>
            <div className={`flex mb-2.5 ${commonTextStyles}`}>
              <span className="mr-1 font-semibold">{summary.emailLabel}</span>
              <p className={commonTextStyles}>{email}</p>
            </div>
            <div className="flex">
              <span className={`mr-1 font-semibold ${commonTextStyles}`}>
                {summary.phoneLabel}
              </span>
              <p className={commonTextStyles}>{phoneNumber}</p>
            </div>
          </div>
          <hr className="my-5" />
        </>
      )}

      {/* Subscription type */}
      <div>
        <div className="flex items-baseline justify-between text-lg">
          <h3 className="subheaders">
            <div>{summary.subscriptionTypeTitle}</div>
            <QuestionTooltip
              tooltipProps={getTootlipProps(
                summary.subscriptionTypeTooltipHeader,
                summary.subscriptionTypeTooltipBody
              )}
            />
          </h3>
        </div>
        <div className="flex items-baseline justify-between text-lg mb-2.5">
          <div className={commonTextStyles}>{planName}</div>
          {shouldShowSection && (
            <div className="text-[18px] leading-[27px]">{amount}</div>
          )}
        </div>
        {shouldShowSection && creditApplied && (
          <div className="flex items-baseline justify-between mb-2.5">
            <div>
              <span>{t("common:billing.summary.label.creditColon")}</span>
            </div>
            <div>{creditApplied}</div>
          </div>
        )}
        {shouldShowSection && (
          <div className="flex items-baseline justify-between">
            <div>
              <span>{vatLabel}</span>
            </div>
            <div>{vatAmount}</div>
          </div>
        )}
        {shouldShowSection && userCouponCodesObject.length > 0 && (
          <div className="flex items-baseline justify-between">
            <div>
              <span>{t("common:billing.summary.label.discount")}</span>
            </div>
            <div>{discountAmount}</div>
          </div>
        )}
        {shouldShowSection && accountCredited && (
          <div className="flex items-baseline justify-between">
            <div>
              <span>{t("common:billing.summary.label.accountCredited")}</span>
            </div>
            <div>{accountCredited}</div>
          </div>
        )}
      </div>
      {!disableDiscountCodeField && (
        <>
          <hr className="my-5" />
          <div className="flex items-end">
            <Input
              type="text"
              label={summary.discountTitle}
              value={discountCodeValue}
              valueChanged={handleDiscountCodeChange}
              className="discount-code__input-LEGACY"
              whiteInput
              maxLength={50}
              annex={
                <QuestionTooltip
                  tooltipProps={getTootlipProps(
                    summary.discountTooltipHeader,
                    summary.discountTooltipBody
                  )}
                />
              }
              disabled={
                isCheckoutPreviewRequestLoading || couponAreaDisableState
              }
            />
            <Button
              type="primary"
              text={summary.add}
              ariaLabel={t("common:billing.summary.label.addDiscountButton")}
              className={`w-auto absolute rounded-none ${
                isMobileView ? "right-[10px]" : "right-5"
              }`}
              onClick={handleAddDiscountCode}
              disabled={
                !discountCodeValue ||
                isCheckoutPreviewRequestLoading ||
                couponAreaDisableState
              }
            />
          </div>
        </>
      )}
      <div>
        {couponErrors.map((error) => (
          <div className="mt-[10px] text-red-700 break-words" key={error}>
            {error}
          </div>
        ))}
      </div>
      <div>
        {userCouponCodesObject.map((coupon) => {
          return (
            <DiscountCouponLEGACY
              key={coupon.couponCode}
              codeToRender={coupon.freeTrialDescription || coupon.couponCode}
              discountCode={coupon.couponCode}
              removeCoupon={handleRemoveDiscountCode}
              disabled={
                loading ||
                coupon.couponCode === DISABILITY_DISCOUNT_CODE_ACC_LEVEL
              }
            />
          );
        })}
      </div>

      <hr className="my-5" />
      <div className="flex items-baseline justify-between boldText text-xl md:text-[26px]">
        <span className="">{summary.total}</span>
        <span className="">{total}</span>
      </div>
      {isMobileView && !isShowMoreExpanded && (
        <div className="flex justify-center mt-[15px]">
          <ShowMoreLessButton
            handleButtonClick={showMore}
            showMoreBtnText={common.showMoreBtnText}
          />
        </div>
      )}
    </div>
  );
};
export default OrderSummaryLEGACY;
