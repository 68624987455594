import React, { FC, useContext } from "react";
import { useTranslation } from "i18n";
import { ExistingPaymentMethod, PaymentMethodType } from "../../types";
import { BillingContext } from "../../BillingContext";

interface Props {
  data: ExistingPaymentMethod | undefined;
  isLoading: boolean;
}

const UseExistingPaymentMethodBlock: FC<Props> = ({
  data,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const { useExistingPaymentMethod } = useContext(BillingContext);

  if (isLoading || !data) return null;

  const isCreditCard = data.paymentMethodType === PaymentMethodType.CreditCard;

  return (
    <div>
      {useExistingPaymentMethod && (
        <div className="p-[10px] bg-grey-whisper">
          <div className="grid grid-cols-12 mb-[5px]">
            <div className="font-semibold col-span-5">
              {t("common:billing.existingMethodBlock.label.nameOnAccount")}
            </div>
            <div className="col-span-7">{data.accountHolderName}</div>
          </div>
          <div className="grid grid-cols-12 mb-[5px]">
            <div className="font-semibold col-span-5">
              {isCreditCard
                ? t("common:billing.existingMethodBlock.label.cardNumber")
                : t("common:billing.existingMethodBlock.label.accountNumber")}
            </div>
            <div className="col-span-7">
              {isCreditCard
                ? `${data.cardNumber} [${data.cardType}]`
                : data.bankAccountNumber}
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="font-semibold col-span-5">
              {t("common:billing.existingMethodBlock.label.billingAddress")}
            </div>
            <div className="col-span-7">{data.address}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UseExistingPaymentMethodBlock;
