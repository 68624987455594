import React, { FC } from "react";
import { Tooltip, ITooltipProps, ITooltipContent } from "../Tooltip/Tooltip";
import cx from "classnames";

export enum QuestionTooltipVariant {
  primary = "primary",
  secondary = "secondary",
}

export interface FormTooltipProps {
  tooltipProps: ITooltipProps;
  variant?: QuestionTooltipVariant;
}

export const getTootlipProps = (
  tooltipHeader: ITooltipContent["header"],
  tooltipBody: ITooltipContent["content"],
  isRichText = false
): ITooltipProps => {
  return {
    texts: {
      header: tooltipHeader,
      content: tooltipBody,
      closeButton: "Close tooltip",
      tooltipName: "Open tooltip",
    },
    config: {
      minimalTopDistance: 50,
      isRichText,
    },
  };
};

const QuestionTooltip: FC<FormTooltipProps> = ({
  tooltipProps,
  variant = QuestionTooltipVariant.primary,
}) => {
  const questionMarkClass = cx(
    "w-5 h-5 text-lg leading-5 text-center cursor-pointer",
    "rounded-3xl shadow-[0px_1px_4px_0px_#00000080]",
    {
      "text-white bg-color-brand-one":
        variant === QuestionTooltipVariant.primary,
      "bg-white text-color-brand-one":
        variant === QuestionTooltipVariant.secondary,
    }
  );
  return (
    <div className="flex flex-row-reverse z-1000">
      <div className={cx("w-5 h-5")}>
        <Tooltip {...tooltipProps}>
          <div className={questionMarkClass}>?</div>
        </Tooltip>
      </div>
    </div>
  );
};

export default QuestionTooltip;
