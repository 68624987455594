import React, { FC, useCallback, useContext, useState } from "react";
import { useTranslation } from "i18n";
import cx from "classnames";
import { format } from "date-fns";
import { Input } from "../../../../Molecules/Input/Input";
import { Button } from "../../../../Atoms/Button/Button";
import "./styles.scss";
import { useBillingTranslations } from "../../hooks/useBillingTranslations";
import { BillingUser } from "translations/src/models/billing";
import { ISubscription, ISummary, ICouponObject, IAddOns } from "../../types";
import DiscountCoupon from "./DiscountCoupon";
import {
  DISABILITY_DISCOUNT_CODE_ACC_LEVEL,
  MAX_COUPONS_ALLOWED_WITH_ACC_LEVEL,
  addOnNamesMap,
} from "../../constants";
import { BillingContext } from "../../BillingContext";
import { ReactComponent as WarningIcon } from "../../../../../images/svg/icons/warning-icon.svg";
import {
  getTootlipProps,
  QuestionTooltip,
  QuestionTooltipVariant,
} from "../../../../Molecules/QuestionTooltip";

interface Props
  extends Omit<
      ISummary,
      "firstName" | "lastName" | "discounts" | "email" | "phoneNumber"
    >,
    Pick<ISubscription, "total" | "amount"> {
  planName: string;
  vatLabel: string;
  isMobileView: boolean | undefined;
  hasAccountDiscount?: boolean;
  vatAmount?: string;
  discountAmount?: string;
  creditApplied?: string;
  accountCredited?: string;
  userCouponCodesObject: ICouponObject[];
  couponErrors: string[];
  setCoupon: (coupon: string) => Promise<void>;
  removeCoupon: (coupon: string) => Promise<void>;
  isCheckoutPreviewRequestLoading: boolean;
  addOns?: IAddOns[];
  agreement?: string;
  planNameTooltip?: {
    header: string;
    body: string;
  };
  nextBillingDate?: {
    date: string;
    price: string;
  };
}

const nextBillingDateFormat = "d MMM y";

const commonTextStyles = "text-[18px] leading-[27px]";

const OrderSummary: FC<Props> = ({
  planName,
  total,
  amount,
  discountAmount,
  creditApplied,
  accountCredited,
  vatLabel,
  vatAmount,
  isMobileView,
  userCouponCodesObject,
  couponErrors,
  setCoupon,
  removeCoupon,
  hasAccountDiscount,
  isCheckoutPreviewRequestLoading,
  addOns,
  agreement,
  planNameTooltip,
  nextBillingDate,
}) => {
  const { disableDiscountCodeField, planPeriodData } =
    useContext(BillingContext);
  const { t } = useTranslation();
  const {
    texts: { summary },
  } = useBillingTranslations(BillingUser.Common);
  const [discountCodeValue, setDiscountCodeValue] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const loadingToggle = () => setLoading((isLoading) => !isLoading);

  const handleDiscountCodeChange = useCallback(
    (val: string) => {
      setDiscountCodeValue(val);
    },
    [setDiscountCodeValue]
  );

  const handleAddDiscountCode = useCallback(async () => {
    if (!discountCodeValue || loading) return;

    loadingToggle();
    await setCoupon(discountCodeValue.trim());
    setDiscountCodeValue("");
    loadingToggle();
  }, [
    discountCodeValue,
    loading,
    setCoupon,
    setDiscountCodeValue,
    loadingToggle,
  ]);

  const handleRemoveDiscountCode = useCallback(
    async (coupon: string) => {
      loadingToggle();
      await removeCoupon(coupon);
      loadingToggle();
    },
    [removeCoupon, loadingToggle]
  );

  const couponAreaDisableState = hasAccountDiscount
    ? userCouponCodesObject.length === MAX_COUPONS_ALLOWED_WITH_ACC_LEVEL
    : userCouponCodesObject.length === 1;

  const commonPriceStyles = "flex items-baseline justify-between";
  const showAddOns =
    planPeriodData.selectedAddOns.length > 0 && addOns && addOns?.length > 0;

  return (
    <div
      className={cx(
        `bg-gradient-to-br from-color-brand-two to-color-brand-one rounded-[10px] font-normal top-24 w-auto sticky text-white`,
        {
          "p-[10px]": isMobileView,
          "p-5": !isMobileView,
        }
      )}
    >
      <h2 className="text-[26px]">{summary.summaryTitle}</h2>
      <hr className="my-5" />
      {/* Subscription type */}
      <div className="flex flex-col gap-2">
        <div className={cx(commonPriceStyles, "text-lg gap-1")}>
          <div className={cx(commonTextStyles, "flex gap-[5px] items-center")}>
            {planName}
            {planNameTooltip && (
              <QuestionTooltip
                tooltipProps={{
                  ...getTootlipProps(
                    t(planNameTooltip.header),
                    t(planNameTooltip.body)
                  ),
                }}
                variant={QuestionTooltipVariant.secondary}
              />
            )}
          </div>
          <div className={commonTextStyles}>
            {amount ?? t("common:billing.checkout.orderSummary.loadingPrice")}
          </div>
        </div>
        {creditApplied && (
          <div className={commonPriceStyles}>
            <div>
              <span>{t("common:billing.summary.label.creditColon")}</span>
            </div>
            <div>{creditApplied}</div>
          </div>
        )}
        {showAddOns &&
          addOns.map((addOn) => {
            const isAddOnSelected = (addOn: IAddOns) => {
              return planPeriodData.selectedAddOns.some(
                (obj) => obj.code === addOn.code
              );
            };

            if (isAddOnSelected(addOn)) {
              return (
                <div className={commonPriceStyles} key={addOn.code}>
                  <div>
                    <span>{t(addOnNamesMap[addOn.code])}</span>
                  </div>
                  <div>
                    {addOn.amount && !isCheckoutPreviewRequestLoading
                      ? addOn.amount
                      : t("common:billing.checkout.orderSummary.loadingPrice")}
                  </div>
                </div>
              );
            }
          })}
        <div className={commonPriceStyles}>
          <div>
            <span>{vatLabel}</span>
          </div>
          <div>
            {vatAmount && !isCheckoutPreviewRequestLoading
              ? vatAmount
              : t("common:billing.checkout.orderSummary.loadingPrice")}
          </div>
        </div>
        {userCouponCodesObject.length > 0 && (
          <div className={commonPriceStyles}>
            <div>
              <span>{t("common:billing.summary.label.discount")}</span>
            </div>
            <div>{discountAmount}</div>
          </div>
        )}
        {accountCredited && (
          <div className={commonPriceStyles}>
            <div>
              <span>{t("common:billing.summary.label.accountCredited")}</span>
            </div>
            <div>{accountCredited}</div>
          </div>
        )}
      </div>

      {!disableDiscountCodeField && !couponAreaDisableState && (
        <>
          <hr className="my-5" />
          <div className="flex items-end">
            <Input
              type="text"
              label={summary.discountTitle}
              value={discountCodeValue}
              valueChanged={handleDiscountCodeChange}
              className="discount-code__input"
              whiteInput
              maxLength={50}
              disabled={
                isCheckoutPreviewRequestLoading || couponAreaDisableState
              }
            />
            <Button
              type="primary"
              text={t("common:button.label.add")}
              ariaLabel={t("common:billing.summary.label.addDiscountButton")}
              className={cx(
                "w-auto absolute rounded-none bg-color-accent-one border-color-accent-one text-black-tertiary",
                {
                  "right-[10px]": isMobileView,
                  "right-5": !isMobileView,
                }
              )}
              onClick={handleAddDiscountCode}
              disabled={
                !discountCodeValue ||
                isCheckoutPreviewRequestLoading ||
                couponAreaDisableState
              }
            />
          </div>
        </>
      )}
      <div>
        {couponErrors.map((error) => (
          <div
            className="flex items-center gap-1 my-1 text-xs break-words"
            key={error}
          >
            <WarningIcon width={16} height={16} className="shrink-0" />
            {error}
          </div>
        ))}
      </div>
      <div>
        {userCouponCodesObject.map((coupon) => {
          return (
            <DiscountCoupon
              key={coupon.couponCode}
              codeToRender={coupon.freeTrialDescription || coupon.couponCode}
              discountCode={coupon.couponCode}
              removeCoupon={handleRemoveDiscountCode}
              disabled={
                loading ||
                coupon.couponCode === DISABILITY_DISCOUNT_CODE_ACC_LEVEL
              }
            />
          );
        })}
      </div>
      <hr className="my-5" />
      <div className="flex items-baseline justify-between text-xl md:text-[30px]">
        <span>{summary.total}</span>
        <span>
          {total && !isCheckoutPreviewRequestLoading
            ? total
            : t("common:billing.checkout.orderSummary.loadingPrice")}
        </span>
      </div>
      {nextBillingDate?.date && (
        <div className="flex justify-between mt-[15px]">
          <span>
            {t("common:billing.summary.label.nextBillDate", {
              date: format(
                new Date(nextBillingDate.date),
                nextBillingDateFormat
              ),
            })}
          </span>
          <span>{nextBillingDate.price}</span>
        </div>
      )}
      {agreement && <p className="mt-[15px] text-[0.88rem]">{t(agreement)}</p>}
    </div>
  );
};

export default OrderSummary;
