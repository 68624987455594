export const userAccountApp = {
  "AccountSettings.MyDetails.MyDetails.updateDetailsButton":
    "update-details-button",
  "AccountSettings.FormSection.Form": "form",
  "AccountSettings.SettingSection.primaryButton": "primary-button",
  "AccountSettings.SettingSection.secondaryButton": "secondary-button",
  "LabelValueRow.LabelValueRow": "-value",
  "MySubscription.BuyAddOn.button": "buy-addon-button",
  "Pagewrapper.Footer.closeButton": "close-button",
  "MySubscription.SubscriptionType.value": "Subscription Type",
  "MySubscription.CurrentPeriod.value": "Current Period",
  "MySubscription.ExpiresOn.value": "Renews/Expires on",
  "MySubscription.AdditionalServices.value": "Additional Services",
  "MyAccountPage.QuickLinks.header": "quick-links-header",
};
