import React from "react";
import "./Footer.scss";
import { SocialList } from "../../Molecules/SocialList/SocialList";
import { Copyright } from "../../Molecules/Copyright/Copyright";
import { LinksListWithTitle } from "../../Molecules/LinksListWithTitle/LinksListWithTitle";
import { ILink } from "../../../generics/interfaces";

/**
 * Expected form of response from API when getting navigation links.
 * navLinks are actually for the header.
 */
export interface INavLinks {
  footerLinks1: ILink[];
  footerLinks2: ILink[];
  footerLinks3: ILink[];
}

export interface IFooterTexts {
  linkListsTitles: string[];
  followCTA: string;
  copyrightTitle: string;
  copyrightAddress: string;
}

export interface IFooterProps {
  /**
   * Navigation links for the footer and header, prefferably /navlinks response from the Charisma api
   */
  navigationLinks: INavLinks;
  /**
   * Text elements to be used throughout the footer
   */
  texts: IFooterTexts;
  /** Optional css class for the topmost div */
  className?: string;
}

export class Footer extends React.Component<IFooterProps> {
  public render() {
    return (
      <footer
        className={`c-spotlight-footer${
          this.props.className ? " " + this.props.className : ""
        }`}
      >
        <div className="c-spotlight-footer__inner">
          <div className="c-spotlight-footer__navigation g-content-area">
            <div className="g-col-lg-3 g-col-md-4">
              <LinksListWithTitle
                links={this.props.navigationLinks["footerLinks1"]}
                titleText={this.props.texts.linkListsTitles[0]}
              />
            </div>
            <div className="g-col-lg-3 g-col-md-4">
              <LinksListWithTitle
                links={this.props.navigationLinks["footerLinks2"]}
                titleText={this.props.texts.linkListsTitles[1]}
              />
            </div>
            <div className="g-col-lg-3 g-col-md-4">
              <LinksListWithTitle
                links={this.props.navigationLinks["footerLinks3"]}
                titleText={this.props.texts.linkListsTitles[2]}
              />
            </div>
            <div className="g-col-lg-9 g-col-md-8 g-hidden-sm" />
            <div className="g-col-lg-3 g-col-md-4">
              <div className="c-spotlight-footer__social">
                <SocialList followCTA={this.props.texts.followCTA} />
              </div>
            </div>
          </div>
        </div>
        <Copyright address={this.props.texts.copyrightAddress} />
      </footer>
    );
  }
}
