import React from "react";
import { CheckoutSuccessPage, Head } from "component-library";
import CheckoutPageWrapper from "./CheckoutPageWrapper";
import { useTranslation } from "i18n";

const CheckoutComplete = () => {
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <title>{t("common:billing.checkoutComplete.pageTitle")}</title>
      </Head>
      <CheckoutPageWrapper>
        <CheckoutSuccessPage />
      </CheckoutPageWrapper>
    </>
  );
};

export default CheckoutComplete;
