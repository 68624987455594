import React from "react";
import { Pill } from "../../../../Atoms/Pill/Pill";
import { TFunction } from "../../../Translations/TranslationContext";

export function getMediaRequestsSubNav(
  t: TFunction,
  isMediaRequestEnabled?: boolean
) {
  const text = t("common:nav.links.mediaRequests");

  return isMediaRequestEnabled
    ? [
        {
          text,
          linkUrl: "/jobs/self-tape-requests",
          renderLabel: (
            <span>
              {text}{" "}
              <Pill
                className="bg-blue-twenty-five ml-1.5"
                theme="light"
                size="md"
              >
                {t("common:nav.links.pill.beta")}
              </Pill>
            </span>
          ),
        },
      ]
    : [];
}
