import React, { FC } from "react";
import cx from "classnames";
import { useTranslation } from "i18n";
import { dataTestIds } from "data-testids";
import { ISubscription } from "../../../types";
import Section from "../../Section";
import { Button } from "../../../../../Atoms/Button/Button";

interface Props {
  subscriptions: ISubscription[];
  selectedSubscriptionId?: string;
  selectSubscription: (subscription: ISubscription) => void;
}

const CDSubscriptionBlock: FC<Props> = ({
  subscriptions,
  selectedSubscriptionId,
  selectSubscription,
}) => {
  const { t } = useTranslation();

  return (
    <Section title={t("common:billing.section.membership")} isOpen>
      <div className="flex flex-col gap-[10px]">
        {subscriptions.map((subscription) => {
          const isSelected = subscription.id === selectedSubscriptionId;
          const textColorCondition = { "text-white": isSelected };

          return (
            <div
              className={cx(
                "flex md:gap-5 max-w-[690px] p-[10px] border-grey-med border-[1px] rounded-[10px] cursor-pointer",
                {
                  "bg-color-brand-two": isSelected,
                  ...textColorCondition,
                }
              )}
              key={subscription.id}
              onClick={() => selectSubscription(subscription)}
            >
              <div className="flex flex-col md:flex-row">
                <div className="flex flex-col">
                  <div className="text-xl font-semibold mb-[10px]">
                    {subscription.name}
                  </div>
                  <div
                    className={cx(
                      "text-m leading-5 text-grey-rolling-stone",
                      textColorCondition
                    )}
                  >
                    {subscription.description}
                  </div>
                </div>
                <div className="flex flex-row gap-[10px] items-center md:gap-0 md:flex-col md:items-end">
                  <div className="text-xl font-semibold md:mb-[10px]">
                    {subscription.total}
                  </div>
                  <div
                    className={cx(
                      "text-m leading-5 text-grey-rolling-stone whitespace-nowrap",
                      textColorCondition
                    )}
                  >
                    ({subscription.amount} + {t("common:billing.label.vat")})
                  </div>
                </div>
              </div>
              <div>
                <Button
                  text={
                    isSelected
                      ? t("common:button.label.selected")
                      : t("common:button.label.choose")
                  }
                  type="primary"
                  size="small"
                  data-testid={
                    dataTestIds.componentLibrary[
                      "Organisms.Billing.CDCheckout.CDSubscriptionBlock.chooseButton"
                    ]
                  }
                  className={cx("py-0 focus:text-color-brand-two", {
                    "bg-white hover:bg-white text-color-brand-two border-none":
                      isSelected,
                  })}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default CDSubscriptionBlock;
