export enum MediaRequestType {
  SelfTape = "SelfTape",
  AudioClip = "AudioClip",
}

export enum ISelfTapeRequestStatus {
  Accepted = "Accepted",
  Rejected = "Rejected",
  Awaiting = "Awaiting",
  Processing = "Processing",
  Sent = "Sent",
  Error = "Error",
  ResponseReceived = "ResponseReceived",
}
