import React from "react";
import ReactDOM from "react-dom";
import cx from "classnames";
import DOMPurify from "dompurify";

import { ReactComponent as SpotlightLogo } from "@assets/svg/Spotlight-Logo-white.svg";

import AccountType from "../../../../constants/AccountType";
import { BurgerIcon } from "./BurgerIcon";
import { ILink } from "../../../../generics/interfaces";
import { Button } from "../../../Atoms/Button/Button";
import {
  MainNavigationItem,
  IMainNavigationItemProps,
} from "../MainNavigationItem/MainNavigationItem";
import { LeftHandNavCanvas } from "../LeftHandNavCanvas/LeftHandNavCanvas";
import {
  SubNavigationItem,
  ISubNavigationItemProps,
} from "../SubNavigationItem/SubNavigationItem";
import { LocalizationOrganism } from "../../LocalizationOrganism/LocalizationOrganism";
import { ILocalizationPopUpTexts } from "../../LocalizationOrganism/LocalizationOrganismTexts";
import { BottomNav } from "../BottomNav/BottomNav";
import { LocalizationButton } from "../../../Atoms/LocalizationButton/LocalizationButton";
import PerformerSearch, {
  IPerformerSearchProps,
} from "../PerformerSearch/PerformerSearch";

import {
  isSmallDeviceWidth,
  isLargeDeviceWidth,
  isIOSDevice,
  addClass,
  removeClass,
} from "../../../../generics/dom-extensions";

const ownStyles = require("./NavigationHeader.scss");
const MENU_TRANSITION_TIME = parseFloat(ownStyles.menuTransitionTime) * 1000;

import "./NavigationHeader.scss";

export interface INavigationHeaderLabelCollection {
  signIn: string;
  signOut: string;
  myAccount: string;
  logoLabel: string;
  flagLabel: string;
  burger: IBurgerInfo;
}

export interface IBurgerInfo {
  labelOpen: string;
  labelClose: string;
  name: string;
}

export interface INavigationHeaderHandlers {
  onSignInHandler?: () => void;
  onSignOutHandler?: () => void;
  onClickCountryIconHandler?: () => void;
  onClickAccountHandler?: () => void;
  onLocalizationChangedHandler?: (language: string, location: string) => void;
}

export interface INavigationHeaderProps {
  /**
   * Element Reference which navigation menu will be placed in.
   */
  menuContainer: React.RefObject<Element>;
  /**
   * Element Reference where localization popup will be placed in.
   */
  popupContainer?: React.RefObject<Element>;
  /**
   * Account type for signed in user
   */
  accountType: AccountType;
  /**
   * User name for left navigation canvas
   */
  userName: string;
  /**
   * Link url for left navigation canvas user name
   */
  userLinkUrl: string;
  /**
   * Texts for the Sign In, Sign Out, My Account buttons
   */
  headerLabels: INavigationHeaderLabelCollection;
  /**
   * Redirection link, where user will be redirected to when clicking on the logo
   */
  logoRedirectionLink: string;
  /**
   * List of navigation links
   */
  links: ILink[];
  /**
   * List of navigation links for bottom nav
   */
  bottomNavLinks: ILink[];
  /**
   * Authentication status
   */
  signedIn: boolean;
  /**
   * Defines handlers for sign in, sign out, click country icon
   */
  handlers: INavigationHeaderHandlers;
  /**
   *  Optional css class for the topmost div
   */
  className?: string;
  /**
   * Optional css class for the navigation menu topmost div
   */
  menuClassName?: string;
  /**
   * Optional css class for left navigation canvas topmost div
   */
  navCanvasClassName?: string;

  /**
   * Defines whether to show or hide localization element
   */
  isLocalizationVisible?: boolean;
  /**
   * ISO 3166-1 country codes (Alpha-2 code) in lower case. ex: gb, fr.
   * Note: Used only with isLocalizationVisible flag enabled
   */
  countryISOCode?: string;
  /**
   * Defines text data for localization organism.
   * If this option provided localization would be handled using LocalizationOrganism component.
   */
  localizationTexts?: ILocalizationPopUpTexts;
  /**
   * List of navigation menu items including sub navigation items
   */
  mainNavData?: INavigation;
  /**
   * Defines properties for performer search
   * If this option provided performer search considered enabled
   */
  performerSearchProps?: IPerformerSearchProps;
}

export interface INavigation {
  isActiveNavigationItem?: (item: IMainNavigationItemProps) => boolean;
  isActiveSubNavigationItem?: (item: ISubNavigationItemProps) => boolean;
  handleNavigationItemClick?: (
    event: React.MouseEvent<HTMLAnchorElement>,
    text: string,
    link: string
  ) => void;
  handleSubNavigationItemClick?: (
    event: React.MouseEvent<HTMLAnchorElement>,
    text: string,
    link: string
  ) => void;
  mainNavigationItems: INavigationItem[];
}

export interface INavigationItem extends IMainNavigationItemProps {
  subNavigationItems?: ISubNavigationItemProps[];
}

export interface INavigationHeaderState {
  isToggled: boolean;
  isOpened: boolean;
  windowWidth: number;
  windowPreviousWidth: number;
}

export const LEFT_HAND_MENU_IS_OPEN_KEY = "leftHandMenuIsOpen";

export class NavigationHeader extends React.Component<
  INavigationHeaderProps,
  INavigationHeaderState
> {
  public menu = React.createRef<HTMLDivElement>();
  public isTestEnv = !window.location.href.includes("spotlight.com");
  private isLarge: boolean;
  private isMobile: boolean;

  constructor(props: INavigationHeaderProps) {
    super(props);

    this.state = {
      isToggled: false,
      isOpened: this.leftMenuShouldOpen(),
      windowWidth: 0,
      windowPreviousWidth: 0,
    };

    this.isMobile = isSmallDeviceWidth();
    this.isLarge = isLargeDeviceWidth();
  }

  public componentDidMount() {
    window.addEventListener("resize", this.handleResize, false);
    this.setState(() => ({ windowWidth: window.innerWidth }));
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize, false);
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  public handleToggleKey = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter" || event.key === " ") {
      this.handleToggle(event);
    }
  };

  private handleResize = (): void => {
    const isPrevLargeScreen = this.isLarge;
    const isLarge = isLargeDeviceWidth();
    const isMobile = isSmallDeviceWidth();
    let prevWidth = this.state.windowPreviousWidth;

    // set new width if menu is opened
    if (this.state.isOpened) {
      prevWidth = this.state.windowWidth;
    }

    // close Left hand navigation if screen size changed from large to any other size
    if (!isLarge && isPrevLargeScreen && this.state.isOpened) {
      this.toggle();
    }

    // update state if switching from/to mobile device or when menu is opened for any device size
    if (isMobile !== this.isMobile || this.state.isOpened) {
      this.isLarge = isLarge;
      this.isMobile = isMobile;
      this.setState({
        windowWidth: window.innerWidth,
        windowPreviousWidth: prevWidth,
      });
    }
  };

  public toggle = (): void => {
    this.isLarge = isLargeDeviceWidth();
    this.setState(
      (previousState: INavigationHeaderState) => ({
        isOpened: !previousState.isOpened,
        isToggled: true,
        windowPreviousWidth: 0,
      }),
      () => {
        if (isIOSDevice()) {
          this.preventSpinnerAnimation();
        }
        this.hideBackToTopOnMobile();
        this.saveLeftMenuIsOpenedState(this.state.isOpened);
      }
    );
  };

  public handleToggle = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    this.toggle();
  };

  public handleOutsideClick = () => {
    this.toggle();
  };

  private preventSpinnerAnimation = () => {
    if (this.state.isOpened) {
      addClass(document.body, "g-prevent-spinner-animation g-no-scroll");
    } else {
      setTimeout(() => {
        removeClass(document.body, "g-prevent-spinner-animation g-no-scroll");
      }, MENU_TRANSITION_TIME);
    }
  };

  private hideBackToTopOnMobile = () => {
    if (this.state.isOpened) {
      addClass(document.body, "g-hide-to-top-small");
    } else {
      removeClass(document.body, "g-hide-to-top-small");
    }
  };

  private onClickCountryIconHandler = () =>
    this.props.handlers.onClickCountryIconHandler &&
    this.props.handlers.onClickCountryIconHandler();
  private onSignInHandler = () =>
    this.props.handlers.onSignInHandler &&
    this.props.handlers.onSignInHandler();
  private onSignOutHandler = () =>
    this.props.handlers.onSignOutHandler &&
    this.props.handlers.onSignOutHandler();
  private onClickAccountHandler = () =>
    this.props.handlers.onClickAccountHandler &&
    this.props.handlers.onClickAccountHandler();

  public render() {
    const buttons = this.getButtons(this.props);
    const links = this.getLinkList(this.props);

    const {
      logoRedirectionLink,
      className,
      menuClassName,
      signedIn,
      children,
      isLocalizationVisible,
      menuContainer,
      mainNavData,
      bottomNavLinks,
      headerLabels: {
        logoLabel,
        burger: {
          labelClose: burgerLabelClose,
          labelOpen: burgerLabelOpen,
          name: burgerName,
        },
      },
      performerSearchProps,
    } = this.props;
    const { isOpened, isToggled } = this.state;

    const searchItem = signedIn && performerSearchProps && (
      <div className="c-navigation-header__search">
        <PerformerSearch {...performerSearchProps} />
      </div>
    );

    const navLinksItem = (
      <nav className="c-navigation-header__container-nav">
        {links}
        {!this.isMobile && searchItem}
        {buttons}
      </nav>
    );
    const localizationItem =
      isLocalizationVisible && this.renderLocalizationItem(this.props);

    const burgerMenuItem = signedIn && (
      <div className="c-navigation-header__container-menu">
        <div
          className={cx("c-navigation-header__nav-item", {
            "nav-item--border-right": !this.isMobile,
          })}
        >
          <a
            href="#/"
            aria-label={isOpened ? burgerLabelClose : burgerLabelOpen}
            onClick={this.handleToggle}
            onKeyPress={this.handleToggleKey}
            className="c-navigation-header__nav-toggle"
            role="button"
          >
            <BurgerIcon
              open={isOpened}
              animated={isToggled && !this.isMobile}
              aria-label={`${isOpened ? "Close" : "Open"} menu`}
            />
            {!this.isMobile && <span className="menu-label">{burgerName}</span>}
          </a>
        </div>
      </div>
    );

    const logoItem = (
      <div className="c-navigation-header__container-logo">
        <a
          href={logoRedirectionLink}
          aria-label={logoLabel || "Spotlight logo"}
          className="c-navigation-header__logo"
        >
          <SpotlightLogo />
        </a>
      </div>
    );

    const testEnvWarningItem = this.isTestEnv && (
      <div className="c-navigation-header__container-test-env-warning">
        Test environment
      </div>
    );

    // combine final layout based on device type
    const layout = this.isMobile ? (
      <React.Fragment>
        {logoItem}
        {testEnvWarningItem}
        {searchItem}
        {burgerMenuItem}
      </React.Fragment>
    ) : (
      <React.Fragment>
        {burgerMenuItem}
        {logoItem}
        {testEnvWarningItem}
        {navLinksItem}
        {localizationItem}
      </React.Fragment>
    );

    return (
      <header className={cx("c-navigation-header", className)}>
        <div className="c-navigation-header__container-root">{layout}</div>
        {menuContainer &&
          menuContainer.current &&
          ReactDOM.createPortal(
            <div
              className={cx(
                "c-navigation-header__menu-container",
                { "c-navigation-header__menu-container--active": isOpened },
                menuClassName
              )}
            >
              <div className="c-navigation-header__menu__blocker" />
              <div
                ref={this.menu}
                className={cx("c-navigation-header__menu", {
                  "c-navigation-header__menu--active": isOpened,
                })}
              >
                <LeftHandNavCanvas
                  userName={this.props.userName}
                  linkUrl={this.props.userLinkUrl}
                  accountType={this.props.accountType}
                  className={this.props.navCanvasClassName}
                >
                  {mainNavData && this.renderMainNavigation(mainNavData)}
                  {children}
                  <BottomNav links={bottomNavLinks} />
                  {this.isMobile && (
                    <div>
                      {navLinksItem}
                      {localizationItem}
                    </div>
                  )}
                </LeftHandNavCanvas>
              </div>
            </div>,
            menuContainer.current
          )}
      </header>
    );
  }

  private getLinkList(props: INavigationHeaderProps): JSX.Element[] {
    const { signedIn, links } = props;
    if (!signedIn && links) {
      return props.links.map((link, index) => (
        <a
          className={
            "c-navigation-header__nav-item c-navigation-header__nav-button"
          }
          key={index}
          href={link.href}
          title={link.title}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(link.html) }}
        />
      ));
    }
    return [];
  }

  private getButtons(props: INavigationHeaderProps): JSX.Element[] {
    const {
      headerLabels: { myAccount, signOut, signIn },
      accountType,
      signedIn,
      isLocalizationVisible,
      userLinkUrl,
    } = props;

    const buttons: JSX.Element[] = signedIn
      ? [
          <Button
            key={0}
            type="primary"
            className={cx(
              "c-navigation-header__nav-button",
              "nav-button-primary",
              `nav-button-underline-${AccountType[accountType]}`
            )}
            text={myAccount}
            href={userLinkUrl}
            onClick={this.onClickAccountHandler}
          />,
          <Button
            key={1}
            type="primary"
            className={cx(
              "c-navigation-header__nav-button",
              "nav-button-primary",
              {
                "nav-button-last-item": !isLocalizationVisible,
              }
            )}
            text={signOut}
            onClick={this.onSignOutHandler}
          />,
        ]
      : [
          <Button
            key={0}
            type="primary"
            className={"c-navigation-header__nav-button"}
            text={signIn}
            onClick={this.onSignInHandler}
          />,
        ];

    return buttons.map((button, index) => (
      <div
        key={index}
        className={"c-navigation-header__nav-item nav-item--border-left"}
      >
        {button}
      </div>
    ));
  }

  private renderMainNavigation(mainNavData: INavigation): JSX.Element[] {
    const {
      isActiveNavigationItem,
      mainNavigationItems,
      isActiveSubNavigationItem,
      handleSubNavigationItemClick,
      handleNavigationItemClick,
    } = mainNavData;

    return mainNavigationItems.map((menuItem, key) => {
      return (
        <MainNavigationItem
          key={key}
          text={menuItem.text}
          renderLabel={menuItem.renderLabel}
          linkUrl={menuItem.linkUrl}
          svgIconStart={menuItem.svgIconStart}
          divider={menuItem?.divider}
          isSelected={
            isActiveNavigationItem
              ? isActiveNavigationItem(menuItem)
              : menuItem.isSelected
          }
          icon={menuItem.icon}
          handleClick={handleNavigationItemClick}
        >
          {menuItem.subNavigationItems &&
            menuItem.subNavigationItems.map((subMenu, subKey) => {
              return (
                <SubNavigationItem
                  key={subKey}
                  text={subMenu.text}
                  linkUrl={subMenu.linkUrl}
                  renderLabel={subMenu.renderLabel}
                  isSelected={
                    isActiveSubNavigationItem
                      ? isActiveSubNavigationItem(subMenu)
                      : subMenu.isSelected
                  }
                  handleClick={handleSubNavigationItemClick}
                />
              );
            })}
        </MainNavigationItem>
      );
    });
  }

  private renderLocalizationItem(props: INavigationHeaderProps): JSX.Element {
    const {
      countryISOCode,
      headerLabels: { flagLabel },
      localizationTexts,
      handlers: { onLocalizationChangedHandler },
      popupContainer,
    } = props;

    return (
      <div className="c-navigation-header__container-country">
        <div className="c-navigation-header__nav-item nav-item--border-left">
          {localizationTexts ? (
            <LocalizationOrganism
              localizationTexts={localizationTexts}
              onLocalizationOptionsSaved={onLocalizationChangedHandler}
              popupContainer={popupContainer}
            />
          ) : (
            <LocalizationButton
              countryISOCode={countryISOCode}
              onClick={this.onClickCountryIconHandler}
              flagLabel={flagLabel}
            />
          )}
        </div>
      </div>
    );
  }

  private leftMenuShouldOpen() {
    const isOpenStoredValue = localStorage.getItem(LEFT_HAND_MENU_IS_OPEN_KEY);
    const isLeftMenuOpen =
      isOpenStoredValue === "true" || isOpenStoredValue === null;

    return isLargeDeviceWidth() && this.props.signedIn && isLeftMenuOpen;
  }

  private saveLeftMenuIsOpenedState = (isOpen: boolean) =>
    localStorage.setItem(LEFT_HAND_MENU_IS_OPEN_KEY, isOpen.toString());
}
