import React, { FC, useContext, useState } from "react";
import cx from "classnames";
import { useTranslation } from "i18n";
import { BillingUser } from "translations/src/models/billing";
import { securityTextCommonStyles } from "../../../constants";
import { BillingAndPaymentFormProps, CheckoutFlow } from "../../../types";
import Section from "../../Section";
import { useBillingTranslations } from "../../../hooks/useBillingTranslations";
import CreditCardForm from "../../CardElements/CreditCardForm";
import { BillingContext } from "../../../BillingContext";
import BillingDetailsForm from "../../CardElements/BillingDetailsForm/BillingDetailsForm";
import { useGetExistingPaymentMethod } from "../../../hooks/useGetExistingPaymentMethod";
import PaymentMethodButton from "../../PaymentMethodButton";
import ExistingPaymentMethodBlock from "../../ExistingPaymentMethodBlock/ExistingPaymentMethodBlock";
import UseExistingPaymentMethodBlock from "../../UseExistingPaymentMethodBlock/UseExistingPaymentMethodBlock";

const CDBillingForm: FC<BillingAndPaymentFormProps> = ({
  isFormOpen,
  formErrors,
  submitForm,
  billingFormRef,
  sectionRef,
  setFieldValue,
  isSubmitted,
  tootlipContainerConst,
  formValues,
  cardDetailError,
  removeInvalidCardError,
  isMobileView,
  cardFormIsVisible,
  isDirectDebit,
}) => {
  const {
    checkoutFlow,
    useExistingPaymentMethod,
    setUseExistingPaymentMethod,
  } = useContext(BillingContext);
  const ifExistingPaymentMethod = checkoutFlow === CheckoutFlow.Default;
  const [useCC, setUseCC] = useState<boolean>(!ifExistingPaymentMethod);
  const { t } = useTranslation();
  const {
    texts: { directDebit, common, paymentDetailForm },
  } = useBillingTranslations(BillingUser.Common);
  const {
    data: existingPaymentMethodData,
    isLoading: existingPaymentMethodIsLoading,
  } = useGetExistingPaymentMethod(ifExistingPaymentMethod);

  const selectMethod = (isCard: boolean) => {
    setUseCC(isCard);
    setUseExistingPaymentMethod(!isCard);
  };

  const formBlockClassName = cx("flex flex-col", {
    "flex-auto max-w-[580px]": isFormOpen,
  });
  const firstError = Object.keys(formErrors)[0];

  return (
    <form
      onSubmit={submitForm}
      ref={billingFormRef}
      className="max-w-[680px] w-full"
    >
      {ifExistingPaymentMethod && (
        <Section
          title={t("common:billing.select.payment.method")}
          isOpen={isFormOpen}
          customClasses="flex flex-col gap-[10px]"
        >
          {existingPaymentMethodData?.paymentMethodType && (
            <PaymentMethodButton
              isSelected={useExistingPaymentMethod}
              onSelect={() => selectMethod(false)}
              variant="secondary"
            >
              <ExistingPaymentMethodBlock data={existingPaymentMethodData} />
            </PaymentMethodButton>
          )}
          <PaymentMethodButton
            text={t("common:button.label.add.cc")}
            isSelected={useCC}
            onSelect={() => selectMethod(true)}
          />
        </Section>
      )}
      <Section
        title={t("common:billing.section.payment.details", {
          position: "",
        })}
        isOpen={isFormOpen && (useCC || useExistingPaymentMethod)}
        customClasses={cx({
          "border-solid border-t border-grey-light pb-0": isMobileView,
        })}
        sectionRef={sectionRef}
      >
        {useExistingPaymentMethod && (
          <UseExistingPaymentMethodBlock
            data={existingPaymentMethodData}
            isLoading={existingPaymentMethodIsLoading}
          />
        )}
        {useCC && (
          <div className={formBlockClassName}>
            <CreditCardForm
              paymentDetailForm={paymentDetailForm}
              cardFormIsVisible={cardFormIsVisible}
              cardDetailError={cardDetailError}
              firstError={firstError}
              tootlipContainerConst={tootlipContainerConst}
              removeInvalidCardError={removeInvalidCardError}
              isMobileView={isMobileView}
            />
            <p
              className={`${securityTextCommonStyles} p-[15px] mb-[15px] bg-grey-98 rounded-[10px]`}
              dangerouslySetInnerHTML={{
                __html: common.securityDescription,
              }}
            />
            <p
              className={`${securityTextCommonStyles} p-[15px] bg-grey-98 rounded-[10px]`}
              dangerouslySetInnerHTML={{
                __html: isDirectDebit
                  ? directDebit.termsAndConditions
                  : common.termsAndConditions,
              }}
            />
            <h2 className="font-semibold mt-5 mb-[40px]">
              {t("common:billing.details.title")}
            </h2>
            <BillingDetailsForm
              formValues={formValues}
              setFieldValue={setFieldValue}
              formErrors={formErrors}
              isSubmitted={isSubmitted}
              firstError={firstError}
              tootlipContainerConst={tootlipContainerConst}
              isMobileView={isMobileView}
              removeInvalidCardError={removeInvalidCardError}
            />
          </div>
        )}
      </Section>
    </form>
  );
};

export default CDBillingForm;
