import React, { SyntheticEvent } from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";
import { Button } from "../../Atoms/Button/Button";

interface IStickyButtonConfig {
  text: string;
  onClick: (e: SyntheticEvent<Element, Event>) => void;
  disabled?: boolean;
  loading?: boolean;
  id?: string;
}

interface IStickyCTABarProps {
  next?: IStickyButtonConfig;
  prev?: IStickyButtonConfig;
  cancel?: IStickyButtonConfig;
  nextUpHint?: string;
  i18nTextsForNextUpHint?: Record<string, string>;
  isSticky?: boolean;
  ctaBarAdditionalClassName?: string;
}

const StickyCTABar: React.FC<IStickyCTABarProps> = ({
  next,
  prev,
  cancel,
  nextUpHint,
  i18nTextsForNextUpHint,
  isSticky = true,
  ctaBarAdditionalClassName,
}) => {
  const shouldShowNextUpHint =
    nextUpHint && i18nTextsForNextUpHint && Object.keys(i18nTextsForNextUpHint);
  const ctaBarWrapperClassNames = cx(
    "bottom-0 z-50 flex justify-center w-full",
    {
      sticky: isSticky,
    }
  );
  const ctaBarClassNames = cx(
    "w-full px-3 py-2 rounded-t-lg bg-grey-whisper max-w-7xl md:py-1",
    ctaBarAdditionalClassName
  );

  return (
    <div id="sticky-bar" className={ctaBarWrapperClassNames}>
      <div
        className={ctaBarClassNames}
        style={{ boxShadow: "0px -6px 10px rgba(0, 0, 0, 0.2)" }}
      >
        <main className="flex items-center w-full">
          {shouldShowNextUpHint && (
            <div className="flex-1 hidden text-lg md:block">
              <span className="font-semibold">
                {i18nTextsForNextUpHint.nextUpLabel}
              </span>
              {nextUpHint}
            </div>
          )}
          <section className="flex flex-col justify-around flex-1 w-full space-y-2 md:justify-start md:space-y-0 md:flex-row-reverse">
            {next && (
              <div className="w-fit-content md:min-w-60 md2:min-w-60 md:ml-2">
                <Button
                  type="primary"
                  text={next.text}
                  disabled={next.disabled}
                  onClick={next.onClick}
                  loading={next.loading}
                  id={next.id}
                  data-testid={
                    dataTestIds.componentLibrary[
                      "Organisms.StickyCTABar.nextButton"
                    ]
                  }
                />
              </div>
            )}
            <div className="flex space-x-2">
              {prev && (
                <div className="w-full md:w-48 md2:w-60">
                  <Button
                    type="secondary"
                    text={prev.text}
                    onClick={prev.onClick}
                    loading={prev.loading}
                    id={prev.id}
                    data-testid={
                      dataTestIds.componentLibrary[
                        "Organisms.StickyCTABar.prevButton"
                      ]
                    }
                  />
                </div>
              )}

              {cancel && (
                <div className="w-full md:w-48 md2:w-60">
                  <Button
                    type="secondary"
                    text={cancel.text}
                    onClick={cancel.onClick}
                    loading={cancel.loading}
                    id={cancel.id}
                    data-testid={
                      dataTestIds.componentLibrary[
                        "Organisms.StickyCTABar.cancelButton"
                      ]
                    }
                  />
                </div>
              )}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default StickyCTABar;
