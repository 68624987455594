import React, { FC, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "i18n";
import cx from "classnames";
import _isEmpty from "lodash/isEmpty";
import { ReactComponent as ChevronIcon } from "component-library/src/images/svg/icons/arrow-right.svg";
import { useDynamicWindowWidthFor } from "shared-hooks";
import { useCanAccessCheckout } from "../../../hooks/useCanAccessCheckout";
import { useGetSubscriptions } from "../../../hooks/useGetSubscriptions";
import { BASE_PERKS } from "./constants";
import { useGetSummary } from "../../../hooks/useGetSummary";
import { PageContent } from "../../../../../../../../../apps/joining-pages/src/components/layout/PageContent";
import { PageTitle } from "../../../../../../../../../apps/joining-pages/src/components/layout/PageTitle";
import { BillingContext } from "../../../BillingContext";
import { SCREEN_WIDTH_ENUM } from "../../../../../../generics/dom-extensions";
import { Spinner } from "../../../../../Atoms/Spinner/Spinner";
import PlanSelector from "../PlanSelector";
import { CheckoutFlow, PlanCodePeriod } from "../../../types";
import StickyCTABar from "../../../../StickyCTABar";
import { USER_ACCOUNT_APP_ROOT } from "../../../constants";
import { AddOnPurchaseModal } from "../AddOnPurchaseModal";
import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";
import {
  getModalTexts,
  getPeriodText,
} from "../PlanSelector/PlanSelector.helpers";

const SubscriptionPage: FC = () => {
  const { t } = useTranslation();
  const isMobileView = useDynamicWindowWidthFor(SCREEN_WIDTH_ENUM.SM);
  const isECPModalVariantFlagActive = useLaunchDarklyFlags(
    FEATURE_FLAGS.ECPModalVariant
  );
  const [isMembershipFeaturesVisible, setIsMembershipFeaturesVisible] =
    useState(false);
  const [isCheckoutSpeedbumpOpen, setIsCheckoutSpeedbumpOpen] = useState(false);
  const {
    goBack,
    goNext,
    planPeriodData,
    setPlanPeriodData,
    toggleAddOnSelected,
    preselectedPlanOverride,
    checkoutFlow,
  } = useContext(BillingContext);

  useCanAccessCheckout(
    checkoutFlow !== CheckoutFlow.Joining,
    USER_ACCOUNT_APP_ROOT
  );

  useEffect(() => {
    if (!preselectedPlanOverride) {
      return;
    }
    if (
      preselectedPlanOverride !== PlanCodePeriod.Annual &&
      preselectedPlanOverride !== PlanCodePeriod.Monthly
    ) {
      return;
    }
    setPlanPeriodData({
      ...planPeriodData,
      selectedPeriod: preselectedPlanOverride as PlanCodePeriod,
    });
  }, []);

  const perks =
    isMobileView && !isMembershipFeaturesVisible ? [BASE_PERKS[0]] : BASE_PERKS;

  const { isLoading: isSummaryLoading, isSuccess: isSummarySuccess } =
    useGetSummary(); // necesary to call /account because on the BE this will transfer any remaining CRM users to recurly
  const { data: subscriptions, isLoading: isSubscriptionLoading } =
    useGetSubscriptions(isSummarySuccess);

  const annualSubscription = subscriptions?.find((sub) =>
    sub.code.includes(PlanCodePeriod.Annual)
  );
  const monthlySubscription = subscriptions?.find((sub) =>
    sub.code.includes(PlanCodePeriod.Monthly)
  );

  const generateContinueButtonText = () => {
    if (!planPeriodData.selectedPeriod)
      return t("common:button.label.continue");

    if (
      planPeriodData.selectedPeriod &&
      planPeriodData.selectedAddOns.length === 0
    )
      return t(
        "common:performer.planSelector.buttons.continueBasicSubscription"
      );

    return t("common:performer.planSelector.buttons.continuePlusAddOns");
  };

  const selectedPeriodAddOns = subscriptions.find(
    (sub) =>
      planPeriodData.selectedPeriod &&
      sub?.code.includes(planPeriodData.selectedPeriod)
  )?.addOns;

  const handleClearSpeedbump = () => {
    setIsCheckoutSpeedbumpOpen(false);
    goNext?.();
  };

  if (isSubscriptionLoading || isSummaryLoading) return <Spinner />;

  return (
    <>
      <header>
        <PageTitle titleText={t("common:billing.subscriptionStep.pageTitle")} />
      </header>
      <PageContent>
        <p className="mb-2 text-base">
          {t("common:performer.subscriptions.perks.description")}{" "}
        </p>
        <div className="flex flex-col items-start gap-2 mb-4">
          {isMobileView && (
            <button
              type="button"
              onClick={() =>
                setIsMembershipFeaturesVisible(!isMembershipFeaturesVisible)
              }
              className="text-color-brand-one"
            >
              <span
                className={cx(
                  "mr-1 inline-flex justify-center items-center rounded-full text-white bg-color-brand-one w-6 h-6 transition-transform duration-300",
                  {
                    "rotate-90": isMembershipFeaturesVisible,
                  }
                )}
              >
                <ChevronIcon className="w-3 h-3 fill-white ml-[1px]" />
              </span>
              <span className="font-semibold">
                {isMembershipFeaturesVisible
                  ? t("common:performer.subscriptions.perks.hideMore")
                  : t("common:performer.subscriptions.perks.showMore")}
              </span>
            </button>
          )}
          <div
            className={
              isMobileView
                ? "flex flex-col gap-1"
                : "flex flex-wrap gap-1 mb-4 text-base"
            }
          >
            {perks.map((perk, index) => (
              <span className="flex items-center font-semibold" key={perk.name}>
                <perk.icon className="w-5 h-5 stroke-[0.2] stroke-black-tertiary mr-1" />
                {t(perk.name)}
                {index !== perks.length - 1 && ","}
              </span>
            ))}{" "}
            {(isMembershipFeaturesVisible || !isMobileView) && (
              <Trans
                i18nKey="common:performer.subscriptions.perks.more"
                components={{
                  a1: (
                    // eslint-disable-next-line -- content is injected by i18n
                    <a
                      href="https://www.spotlight.com/the-small-print/what-we-offer-our-members/"
                      target="_blank"
                    />
                  ),
                }}
              />
            )}
          </div>
        </div>
        {(!_isEmpty(annualSubscription) || !_isEmpty(monthlySubscription)) && (
          <>
            <PlanSelector
              selectedPeriod={planPeriodData.selectedPeriod}
              setSelectedPeriod={(plan: PlanCodePeriod) => {
                setIsCheckoutSpeedbumpOpen(true);
                setPlanPeriodData({
                  selectedPeriod: plan,
                  selectedAddOns: [],
                });
              }}
              setIsAddOnSelected={(addOn) => toggleAddOnSelected(addOn)}
              subscriptions={[annualSubscription, monthlySubscription]}
            />
            {isECPModalVariantFlagActive && (
              <AddOnPurchaseModal
                preselectedAddOn={selectedPeriodAddOns?.[0]}
                closePopUp={() => {
                  setIsCheckoutSpeedbumpOpen(false);
                  setPlanPeriodData({
                    selectedPeriod: undefined,
                    selectedAddOns: [],
                  });
                }}
                onSuccessCallback={() => {}}
                texts={{
                  period: getPeriodText(planPeriodData.selectedPeriod!),
                  disclaimer: getModalTexts(true).disclaimer,
                  disclaimerLink: getModalTexts(true).disclaimerLink,
                }}
                onPrimaryCtaClick={() => {
                  selectedPeriodAddOns?.[0] &&
                    toggleAddOnSelected(selectedPeriodAddOns[0]);
                  handleClearSpeedbump && handleClearSpeedbump();
                }}
                onSecondaryCtaClick={handleClearSpeedbump}
                isOpen={isCheckoutSpeedbumpOpen}
              />
            )}
          </>
        )}
      </PageContent>
      {!isECPModalVariantFlagActive && (
        <StickyCTABar
          prev={{
            text: t("common:button.label.back"),
            onClick: () => goBack?.(),
          }}
          next={{
            text: generateContinueButtonText(),
            onClick: () => goNext?.(),
            disabled: !planPeriodData.selectedPeriod,
          }}
          isSticky
        />
      )}
    </>
  );
};

export default SubscriptionPage;
