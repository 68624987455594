import React from "react";
import { useFormikContext } from "formik";

import { usePrevious } from "./usePrevious";

/**
 * Scrolls the user to the first error in a
 * Formik form and focuses on the form input
 * Returns a ref to the field and the container
 * of the ref so that it scroll to the label also
 */
export const useFormikScrollToError = <
  T extends HTMLElement, // field ref
  U extends HTMLElement // container ref
>(
  fieldName: string,
  isDatePicker?: boolean
) => {
  const { errors, isSubmitting } = useFormikContext();

  // the field itself
  const fieldRef = React.useRef<T>(null);
  // the container of the field including the label so the entirety is scrolled in to view
  const containerElementRef = React.useRef<U>(null);

  const wasSubmitting = usePrevious(isSubmitting);

  React.useEffect(() => {
    const firstError = Object.keys(errors)[0];

    if (
      wasSubmitting === true &&
      !isSubmitting &&
      firstError === fieldName &&
      fieldRef.current !== null
    ) {
      const currentScrollPosition =
        document.documentElement.scrollTop || document.body.scrollTop;

      // focus field
      if (isDatePicker) {
        // @ts-ignore
        fieldRef.current?.setFocus?.();
      } else {
        fieldRef.current?.focus?.();
      }

      // immediately scroll back to previous position so that scroll is smooth
      window.scrollTo({
        top: currentScrollPosition,
        left: 0,
        behavior: "auto",
      });

      // scroll container element into view, otherwise field element
      if (containerElementRef.current) {
        containerElementRef.current.scrollIntoView({
          behavior: "smooth",
          inline: "start",
          block: "nearest",
        });
      } else {
        fieldRef.current.scrollIntoView({
          behavior: "smooth",
          inline: "start",
          block: "nearest",
        });
      }
    }
  }, [wasSubmitting, isSubmitting, errors, fieldName]);

  return { fieldRef, containerElementRef };
};
