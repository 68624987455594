import React from "react";
import cx from "classnames";

import { getTranslation } from "../../../translations/translationManager";

import { IFilterTagProps } from "../../Atoms/FilterTag/FilterTag";
import { TagsPanel } from "./TagsPanel/TagsPanel";

import "./FilterControl.scss";
import TagsOverviewPopup from "./TagsOverviewPopup/TagsOverviewPopup";
import { Button } from "../../Atoms/Button/Button";

const ITEMS_COUNT_LARGE_DEVICE = 4;

export interface IFilterControlProps {
  /**
   * Collection of filter elements to be displayed in the filter control
   */
  items: JSX.Element[];
  /**
   * Optional: Locale for the filter component
   */
  locale?: string;
  /**
   * Optional: Label for the View all filters button
   */
  viewAllButtonLabel?: string;
  /**
   *  Collection of selected tags  to be displayed in the filter control
   */
  tags: IFilterTagProps[];
  /**
   * Callback for Clear All click
   */
  onClearAllFilters?: () => void;
  /**
   * Obsolete
   */
  onTagCounterClick?: (
    event: React.MouseEvent<HTMLDivElement>,
    tags: IFilterTagProps[]
  ) => void;
  /**
   * Callback for TagsOverviewPopup apply tags
   */
  onApplyTags?: (tags: IFilterTagProps[]) => void;
  /**
   * Callback for view all filters click
   */
  onViewAllFilters?: () => void;
}

export interface IFilterControlState {
  isTagsOverviewPopupShown: boolean;
}

export class FilterControl extends React.Component<
  IFilterControlProps,
  IFilterControlState
> {
  public readonly state: Readonly<IFilterControlState> = {
    isTagsOverviewPopupShown: false,
  };

  public render() {
    const { items, tags, onClearAllFilters } = this.props;
    const translations = this.translations;

    // for certain amount of elements layout can be like for medium device
    const isMediumLayout = items.length < ITEMS_COUNT_LARGE_DEVICE;

    return (
      <div className="c-filter-control">
        <div className="g-content-area">
          <div
            className={cx(
              "c-filter-control__filter-row g-col-lg-12",
              isMediumLayout && "c-filter-control__filter-row--medium"
            )}
          >
            {items.slice(0, ITEMS_COUNT_LARGE_DEVICE).map((item, ind) => (
              <div key={ind} className="c-filter-control__filter-row-item">
                {item}
              </div>
            ))}
            <div className="c-filter-control__filter-row-view-all">
              <Button
                type="tertiary"
                text={this.getViewAllButtonLabel()}
                iconName="icon-filter"
                onClick={this.handleViewAllFilters}
              />
            </div>
          </div>
          <TagsPanel
            tags={tags}
            texts={translations}
            onTagCounterClick={this.handleTagCounterClick}
            onClearAllFilters={onClearAllFilters}
          />
        </div>
        {this.props.onApplyTags && this.state.isTagsOverviewPopupShown && (
          <TagsOverviewPopup
            filterTags={this.props.tags.map((val) => ({
              ...val,
              onClick: undefined,
            }))}
            title={translations.tagOverviewPopup.title}
            saveButtonText={translations.tagOverviewPopup.saveButtonText}
            hidePopup={this.hidePopup}
            onSave={this.props.onApplyTags}
          />
        )}
      </div>
    );
  }

  private handleTagCounterClick = (
    event: React.MouseEvent<HTMLDivElement>,
    tags: IFilterTagProps[]
  ) => {
    this.props.onTagCounterClick?.(event, tags);
    this.setState({ isTagsOverviewPopupShown: true });
  };

  private hidePopup = () => {
    this.setState({ isTagsOverviewPopupShown: false });
  };

  private get translations() {
    return getTranslation(this.props.locale).filterControl;
  }

  private getViewAllButtonLabel = (): string =>
    this.props.viewAllButtonLabel || this.translations.ctaAllFilters;

  private handleViewAllFilters = () => {
    this.props.onViewAllFilters && this.props.onViewAllFilters();
  };
}
