import React, { FC } from "react";
import { connect } from "react-redux";
import {
  AccountType,
  AddOnEntitlements,
  INavigationHeaderProps,
  IPerformerSearchResult,
  LocalizationUtils,
  NavigationHeader,
  PremiumEntitlements,
  getNavHeaderLinks,
} from "component-library";
import { authorizationService, UserAttributes } from "shared-auth";
import config from "config/global";
import { RoxFlag } from "config/rox";

import { IApplicationState } from "store/index";
import { hidePopup, setLocale, showPopup } from "store/ui/ui.actions";
import { HidePopup, SetLocale, ShowPopup } from "store/ui/ui.types";

import { MapDispatchToProps } from "types/utilities";

import { IUser } from "models/user";

import "./HeaderContainer.scss";
import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";

type LocalizationChangeHandler =
  INavigationHeaderProps["handlers"]["onLocalizationChangedHandler"];

export interface IHeaderContainerActions {
  showPopup: ShowPopup;
  hidePopup: HidePopup;
  setLocale: SetLocale;
}

export interface IHeaderContainerStoreProps {
  user: IUser | undefined;
  locale: string;
  isLocalizationVisible: boolean;
  isPremiumSearchPerformerFeatureFlagOn: boolean;
}

export interface IHeaderContainerProps
  extends IHeaderContainerStoreProps,
    IHeaderContainerActions {
  menuContainer: React.RefObject<HTMLDivElement>;
  popupContainer: React.RefObject<HTMLDivElement>;
  agentQuickSearchPlaceholder: string;
}

export const HeaderContainer: FC<IHeaderContainerProps> = (props) => {
  const memberGroup = authorizationService.getUserGroup();

  // replace with checkIsCastingBBC when authorizationService for this app
  const isCastingBBC =
    authorizationService.getUserAttribute(UserAttributes.IdentityProvider) ===
    "ipcheck";
  const isPremiumLEGACY = authorizationService.doesUserHaveEntitlements(
    PremiumEntitlements.Premium_Site_Access
  );
  const isAddonPerformer =
    authorizationService.doesUserHaveEntitlements(
      AddOnEntitlements.AddOnBundle
    ) || isPremiumLEGACY;
  const isAgencySettingsFlagEnabled = useLaunchDarklyFlags(
    FEATURE_FLAGS.AgencySettings
  );
  const isMediaRequestEnabled = useLaunchDarklyFlags(FEATURE_FLAGS.SelfTapes);

  const { lhNavBottomLinks, headerLabels, localPopUpText, mainNav } =
    getNavHeaderLinks(memberGroup, {
      isCastingBBC,
      isAddonPerformer,
      isAgencySettingsFlagEnabled,
      isMediaRequestEnabled,
    });

  const {
    isLocalizationVisible,
    menuContainer,
    popupContainer,
    user,
    agentQuickSearchPlaceholder,
    isPremiumSearchPerformerFeatureFlagOn,
  } = props;

  const handleLocalizationChange: LocalizationChangeHandler = (
    language,
    location
  ) => {
    props.setLocale(LocalizationUtils.getLocalizationCode(language, location));
  };
  const accountPageUrl = headerLabels.myAccountLink;

  const handleSignOutClick = () => {
    authorizationService.signoutPortalRedirect();
  };

  const handleAccountClick = (): void => {
    window.location.assign(accountPageUrl);
  };

  const handlers: INavigationHeaderProps["handlers"] = {
    onSignOutHandler: handleSignOutClick,
    onClickAccountHandler: handleAccountClick,
    onLocalizationChangedHandler: handleLocalizationChange,
  };

  const performerSearchProps = {
    content: { inputPlaceholder: agentQuickSearchPlaceholder },
    showMoreResults: (searchTerm: string) =>
      (window.location.href = `${config.siteRootUrl}jobs/performers/search/${searchTerm}`),
    loadSearchResults: () => new Promise<IPerformerSearchResult[]>(() => []),
  };

  const shouldShowSearchPerformer = () => {
    const isAgent = user?.type === AccountType.Agent;

    if (isAgent) {
      return performerSearchProps;
    }

    if (!isPremiumSearchPerformerFeatureFlagOn) {
      return undefined;
    }

    const isPerformer = user?.type === AccountType.Performer;
    const isYoungPerformer = user?.memberGroups?.some(
      (group) =>
        group.group === "performer" && group.subType === "youngPerformer"
    );
    const userHasEntitlements = authorizationService.doesUserHaveEntitlements(
      PremiumEntitlements.Premium_Performer_Search
    );

    // only premium main performers with flag on
    const mainPerformerCanSearchPerformers =
      isPerformer && !isYoungPerformer && userHasEntitlements;

    if (mainPerformerCanSearchPerformers) {
      return performerSearchProps;
    }

    return undefined;
  };

  return (
    <NavigationHeader
      bottomNavLinks={lhNavBottomLinks}
      menuContainer={menuContainer}
      popupContainer={popupContainer}
      accountType={user?.type ?? AccountType.DefaultUser}
      headerLabels={headerLabels}
      logoRedirectionLink={config.marketingSiteUrl}
      links={[]}
      signedIn={true}
      isLocalizationVisible={isLocalizationVisible}
      countryISOCode={
        LocalizationUtils.getUserLocalizationPreference().location
      }
      handlers={handlers}
      menuClassName="c-header-section__navigation-menu"
      userName={user?.username ?? "Loading..."}
      mainNavData={mainNav}
      userLinkUrl={accountPageUrl}
      localizationTexts={localPopUpText}
      //@ts-ignore
      performerSearchProps={shouldShowSearchPerformer()}
    />
  );
};

const mapDispatchToProps: MapDispatchToProps<IHeaderContainerActions> = {
  showPopup,
  hidePopup,
  setLocale,
};

const mapStateToProps = (
  state: IApplicationState
): IHeaderContainerStoreProps => {
  const user = state.user.data;

  return {
    user,
    locale: state.ui.locale,
    isLocalizationVisible:
      state.rox.configuration?.[RoxFlag.LocalizationControl].isEnabled() ??
      false,
    isPremiumSearchPerformerFeatureFlagOn:
      state.rox.configuration?.[RoxFlag.Premium_PerformerSearch].isEnabled() ??
      false,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
