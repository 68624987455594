import React, { FC, useContext, useEffect } from "react";
import cx from "classnames";
import { useTranslation, Trans } from "i18n";
import { ReactComponent as ClassicIcon } from "../../../../../../../images/svg/icons/classic-benefit-success.svg";
import { ReactComponent as PremiumIcon } from "../../../../../../../images/svg/icons/premium-benefit-success.svg";
import { PlanCodePeriod, PlanFilter } from "../../../../types";
import { useDynamicWindowWidthFor } from "shared-hooks";
import { SCREEN_WIDTH_ENUM } from "../../../../../../../generics/dom-extensions";
import { Button } from "../../../../../../Atoms/Button/Button";
import { BillingContext } from "../../../../BillingContext";
import "./styles.scss";

interface Props {
  isSelected: boolean;
  onSelect: () => void;
  planType: PlanFilter;
  planPeriod: PlanCodePeriod;
  currency?: string;
  price?: string;
  totalPrice?: string;
  isPromoEnabled?: boolean;
}

const currencyPrice: Record<string, string> = {
  GBP: "£294",
  EUR: "€345",
};

const currencyDiscount: Record<string, string> = {
  GBP: "£54",
  EUR: "€69",
};

const monthPriceClassic: Record<string, string> = {
  GBP: "£15.30",
  EUR: "€17.75",
};

const monthPricePremium: Record<string, string> = {
  GBP: "£28.50",
  EUR: "€33.50",
};

const classicBenefits = Array.from(Array(8).keys());
const premiumBenefits = Array.from(Array(10).keys());

const SubscriptionCard: FC<Props> = ({
  isSelected,
  onSelect,
  planType,
  planPeriod,
  price,
  totalPrice,
  isPromoEnabled,
  currency = "",
}) => {
  const { customTexts } = useContext(BillingContext);
  const isMobileView = useDynamicWindowWidthFor(SCREEN_WIDTH_ENUM.SM);
  const { t } = useTranslation();
  const [isVisibleBenefits, setIsVisibleBenefits] = React.useState<boolean>(
    !isMobileView
  );

  useEffect(() => setIsVisibleBenefits(!isMobileView), [isMobileView]);

  const cardClass = cx(`subscription-card subscription-card--${planType}`, {
    "subscription-card--selected": isSelected,
  });

  const isPremium = planType === PlanFilter.Premium;
  const isAnnual = planPeriod === PlanCodePeriod.Annual;

  const chooseButtonText = isPremium
    ? customTexts?.premiumCardButton
    : customTexts?.classicCardButton;
  const selectedButtonCustomText = isPremium
    ? customTexts?.premiumCardSelectedButton
    : customTexts?.classicCardSelectedButton;
  const selectedButtonText =
    selectedButtonCustomText ?? "common:button.label.selected";
  const benefits = isPremium ? premiumBenefits : classicBenefits;

  const isPromo = isAnnual && isPromoEnabled;
  const monthPricePromo = isPremium ? monthPricePremium : monthPriceClassic;
  const benefitIcon = isPremium ? <PremiumIcon /> : <ClassicIcon />;
  const showHideClassicText = isVisibleBenefits
    ? "common:button.label.hide.classic.benefits"
    : "common:button.label.show.classic.benefits";
  const showHidePremiumText = isVisibleBenefits
    ? "common:button.label.hide.premium.benefits"
    : "common:button.label.show.premium.benefits";

  return (
    <div className={cardClass} onClick={onSelect}>
      <div className="subscription-card__body bg-grey-snow">
        <div className="header-block">
          <p className="header-block__header">
            {isPremium
              ? t("common:billing.premiere")
              : t("common:billing.classic")}
          </p>
          <p className="header-block__text">
            {isPremium
              ? t("common:billing.premiere.descr")
              : t("common:billing.classic.descr")}
          </p>
          <p className="header-block__text">
            {isPromo && isPremium && (
              <>
                <span className="line-through header-block__price">
                  {currencyPrice[currency]}
                </span>{" "}
              </>
            )}
            <span className="header-block__price">{totalPrice}</span> /{" "}
            {planPeriod === PlanCodePeriod.Annual
              ? t("common:label.year")
              : t("common:label.month")}
            {isPromo && isAnnual && (
              <>
                <br />
                {t("common:billing.promo.billed.annually", {
                  amount: monthPricePromo[currency],
                })}
              </>
            )}
          </p>
          <p className="header-block__text">
            {t("common:billing.vat", { price })}
            {planPeriod === PlanCodePeriod.Monthly && (
              <>
                <br />
                {t("common:billing.dd.only")}
              </>
            )}
          </p>
        </div>
        {isPromo && isPremium && (
          <div className="promo-block">
            {t("common:billing.promo.strip", {
              amount: currencyDiscount[currency],
            })}
          </div>
        )}
        {isMobileView && (
          <Button
            className="show-benefits-button"
            text={
              <div
                className={cx("show-benefits-button__text", {
                  "show-benefits-button__text--premium": isPremium,
                  "show-benefits-button__text--classic": !isPremium,
                })}
              >
                {benefitIcon}{" "}
                {t(isPremium ? showHidePremiumText : showHideClassicText)}
              </div>
            }
            onClick={(e) => {
              e.stopPropagation();
              setIsVisibleBenefits(!isVisibleBenefits);
            }}
          />
        )}
        {isVisibleBenefits && (
          <div className="description-block">
            <div className="description-block__header">
              {isPremium
                ? t("common:billing.planFeature.premium")
                : t("common:billing.planFeature.classic")}
            </div>
            <div className="description-block__benefits">
              {benefits.map((item) => {
                const key = `billing.planFeature.${planType}.${item}`;

                return (
                  <div key={key} className="description-block__benefit">
                    <div className="description-block__benefit-icon">
                      {benefitIcon}
                    </div>
                    <p>
                      <Trans
                        i18nKey={key}
                        components={{ strong: <strong /> }}
                      />
                    </p>
                  </div>
                );
              })}

              <div className="description-block__benefit">
                <div className="description-block__benefit-icon" />
                <p>
                  <Trans
                    i18nKey="common:billing.planFeature.learn.more"
                    components={{
                      strong: (
                        <strong
                          onClick={() =>
                            window.open(customTexts?.benefitsLink, "_blank")
                          }
                        />
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="cta-section">
          <div
            className={`cta-section__button cta-section__button--${planType} max-w-[95%]`}
          >
            {t(isSelected ? selectedButtonText : chooseButtonText)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
