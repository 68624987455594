import {
  ExpiryTimeUnits,
  getCookie,
  getCookieDomain,
  setCookie,
} from "../../../generics/cookies";

export const SPOTLIGHT_LANGUAGE_COOKIE_NAME = "spotlight.localization.language";
export const SPOTLIGHT_LOCATION_COOKIE_NAME = "spotlight.localization.region";
export const DEFAULT_LANGUAGE = "en";
export const DEFAULT_LOCATION = "GB";
export const DEFAULT_LOCALE = getLocalizationCode(
  DEFAULT_LANGUAGE,
  DEFAULT_LOCATION
);

export const LOCATION_LANGUAGE_MAPPING = [
  {
    location: {
      name: "", // These names aren't actually displayed anywhere, but the LocalizationDialog calls for them to be IOption
      value: "GB",
    },
    languages: [
      {
        name: "",
        value: "en",
      },
    ],
  },
  {
    location: {
      name: "",
      value: "IE",
    },
    languages: [
      {
        name: "",
        value: "en",
      },
    ],
  },
  {
    location: {
      name: "",
      value: "FR",
    },
    languages: [
      {
        name: "",
        value: "fr",
      },
    ],
  },
];

// Build a mapping of the valid localization combinations based on the mapping above
const VALID_LOCALIZATION_LOOKUP: Record<string, boolean> = {};
for (const localizationMapping of LOCATION_LANGUAGE_MAPPING) {
  for (const language of localizationMapping.languages) {
    VALID_LOCALIZATION_LOOKUP[
      getLocalizationCode(language.value, localizationMapping.location.value)
    ] = true;
  }
}

export function getLocalizationCode(language: string, location: string) {
  return language.toLowerCase() + "-" + location.toUpperCase();
}

export interface ILocalizationInfo {
  /** The two letter country code of the location. This will be uppercased. */
  location: string;
  /** The two letter language code of the language. The will be lowercased. */
  language: string;
  /** The locale of the localization, with the first two letters being lowercase and the last two letters being uppercased. */
  locale: string;
}

/**
 * Returns the user's language preference from the cookie.
 * The language is always lowercase, the location is always uppercase.
 * The language and location combination is validated to be correct and defaults to en-GB, as the cookie could have an invalid
 * value.
 */
export function getUserLocalizationPreference(): Readonly<ILocalizationInfo> {
  let language = (
    getCookie(SPOTLIGHT_LANGUAGE_COOKIE_NAME) || DEFAULT_LANGUAGE
  ).toLowerCase();
  let location = (
    getCookie(SPOTLIGHT_LOCATION_COOKIE_NAME) || DEFAULT_LOCATION
  ).toUpperCase();

  // TEMPORARY HACK: Force language and location to en-GB, so that all pages request English translations
  language = "en";
  location = "GB";

  const locale = getLocalizationCode(language, location);
  if (!VALID_LOCALIZATION_LOOKUP[locale]) {
    return {
      language: DEFAULT_LANGUAGE,
      location: DEFAULT_LOCATION,
      locale: DEFAULT_LOCALE,
    };
  }

  return {
    language,
    location,
    locale,
  };
}

export function setUserLocalizationPreference(
  language: string,
  location: string
) {
  const cookieOptions = {
    expiryTime: 365,
    domain: getCookieDomain(),
    expiryTimeUnit: ExpiryTimeUnits.DAY,
  };
  setCookie(SPOTLIGHT_LANGUAGE_COOKIE_NAME, language, cookieOptions);
  setCookie(SPOTLIGHT_LOCATION_COOKIE_NAME, location, cookieOptions);
}
