import React, { FC, useContext, useState } from "react";
import { Trans, useTranslation } from "i18n";
import { dataTestIds } from "data-testids";
import recurlyLogo from "../../../../../../images/recurly-logo.png";
import { CheckoutFlow, PlanFilter } from "../../../types";
import { BillingContext } from "../../../BillingContext";
import PlanInfoModal from "../../PlanInfoModal";
import CommonHeader from "./CommonHeader";

const HeaderBlock: FC = () => {
  const { t } = useTranslation();
  const { planTypeFilter, setPlanTypeFilter, isCommonHeader, checkoutFlow } =
    useContext(BillingContext);
  const [modalIOpen, setModalIsOpen] = useState(false);

  const isClassicPlan = planTypeFilter === PlanFilter.Classic;
  const planType = isClassicPlan ? PlanFilter.Premium : PlanFilter.Classic;
  const isJoining = checkoutFlow === CheckoutFlow.Joining;
  const isDefault = checkoutFlow === CheckoutFlow.Default; // default is renewal/reactivation

  if (isCommonHeader) return <CommonHeader />;

  return (
    <>
      <div>
        <p className="pt-[15px]">
          {isDefault && (
            <Trans
              i18nKey={"common:billing.updateSubscription.renewal.header"}
              components={{
                a: (
                  <a
                    href="https://www.spotlight.com/join-us/premiere-member/"
                    target="_blank"
                  />
                ),
              }}
            />
          )}
          {isJoining && (
            <Trans
              i18nKey={"common:billing.updateSubscription.joining.header"}
              components={{
                b: (
                  <b
                    onClick={() => setModalIsOpen(true)}
                    className="cursor-pointer"
                  />
                ),
              }}
            />
          )}
        </p>
        <div className="mt-[10px]">
          {t("common:billing.updateSubscription.youAreViewing")}
        </div>
        <div
          className="py-[9px] px-[15px] font-[450] text-[22px] uppercase
                   bg-grey-whisper color-black-tertiary max-w-[390px]"
          data-testid={
            dataTestIds.componentLibrary[
              "Organisms.Billing.AddOnCheckout.Header.subscriptionView"
            ]
          }
        >
          {planTypeFilter === PlanFilter.Premium
            ? t("common:billing.updateSubscription.premiumPlan")
            : t("common:billing.updateSubscription.classicPlan")}
        </div>
        <a
          onClick={() => setPlanTypeFilter(planType)}
          className="mt-[22px] font-semibold text-blue-link inline-block cursor-pointer"
          data-testid={
            dataTestIds.componentLibrary[
              "Organisms.Billing.AddOnCheckout.Header.subscriptionLink"
            ]
          }
        >
          {isClassicPlan
            ? t("common:billing.updateSubscription.viewPremiumPlans")
            : t("common:billing.updateSubscription.viewClassicPlans")}
        </a>
        <p>
          {isDefault && (
            <Trans
              i18nKey="common:billing.updateSubscription.renewal.description"
              components={{
                br: <br />,
                a1: (
                  <a
                    href="https://www.spotlight.com/the-small-print/terms-conditions/"
                    target="_blank"
                  />
                ),
                a2: (
                  <a
                    href="https://www.spotlight.com/get-in-touch"
                    target="_blank"
                  />
                ),
              }}
            />
          )}
          {isJoining && (
            <Trans
              i18nKey="common:billing.updateSubscription.joining.description"
              components={{
                br: <br />,
                a: (
                  <a
                    href="https://www.spotlight.com/the-small-print/terms-conditions/"
                    target="_blank"
                  />
                ),
              }}
            />
          )}
        </p>
        <p className="flex items-center gap-[10px]">
          {t("common:billing.updateSubscription.paymentAre")}{" "}
          <img src={recurlyLogo} alt="" />
        </p>
      </div>
      <PlanInfoModal
        isOpen={modalIOpen}
        closePopUp={() => setModalIsOpen(false)}
      />
    </>
  );
};

export default HeaderBlock;
