import React from "react";
import cx from "classnames";
import { useField, FieldHookConfig } from "formik";
import { useFormikScrollToError } from "shared-hooks";

import { InputLabel } from "../../InputsCommon/InputLabel";
import { InputWrapper, ERROR } from "../../InputsCommon/InputWrapper";
import { InputSizes } from "../../InputsCommon/BaseInputProps";

import "./TextInputFormik.scss";

interface ITextInputFormikProps {
  inputClassName?: string;
  className?: string;
  children?: React.ReactNode;
  size?: InputSizes;
  annex?: JSX.Element;
  label?: string;
  whiteInput?: boolean;
  password?: boolean;
  transformInput?: (value: string) => string;
  hideErrorMessage?: boolean;
  tooltipComponent?: () => JSX.Element;
  shouldScrollToView?: boolean;
  maxLength?: number;
}

export const TextInputFormik: React.FC<
  ITextInputFormikProps & FieldHookConfig<string>
> = (props) => {
  const {
    inputClassName,
    className,
    children,
    size = InputSizes.Large,
    annex,
    label,
    whiteInput,
    password,
    transformInput,
    hideErrorMessage,
    tooltipComponent,
    shouldScrollToView = true,
    maxLength,
    ...rest
  } = props;
  const { containerElementRef, fieldRef } = useFormikScrollToError<
    HTMLInputElement,
    HTMLDivElement
  >(rest.name);

  const [field, meta, helpers] = useField(rest);
  const invalid = meta.touched && meta.error ? ERROR : undefined;
  const errorMessage = invalid && !hideErrorMessage ? meta.error : undefined;

  const combinedClassName = cx("c-input", className);

  return (
    <div
      className={combinedClassName}
      ref={shouldScrollToView ? containerElementRef : null}
    >
      <InputWrapper
        invalid={invalid}
        validationMesssage={errorMessage}
        className={inputClassName}
      >
        <div className="flex items-baseline justify-between">
          <InputLabel label={label} annex={annex} htmlFor={field.name} />
          {tooltipComponent && tooltipComponent()}
        </div>
        <div
          className={cx("c-input__container", `c-input__container--${size}`)}
        >
          <input
            {...field}
            disabled={rest.disabled}
            maxLength={maxLength}
            id={field.name}
            ref={shouldScrollToView ? fieldRef : null}
            placeholder={rest.placeholder}
            className={cx("c-input-field", {
              "c-input-field--white": whiteInput,
            })}
            type={password ? "password" : "text"}
            onChange={(e) => {
              let value = e.target.value;
              if (transformInput) value = transformInput(value);
              helpers.setValue(value);
            }}
          />
          {children}
        </div>
      </InputWrapper>
    </div>
  );
};
