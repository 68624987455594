interface OidcConfig {
  authority: string;
  redirect_uri: string;
  client_id: string;
  post_logout_redirect_uri: string;
  response_type: "code";
  scope: string;
}

declare const __CONFIG__: {
  siteRootUrl: string;
  umbracoApiUrl: string;
  whoAmIUrl: string;
  userAccountAppBaseUrl: string;
  marketingSiteUrl: string;
  userAccountApiUrl: string;
  billingApiUrl: string;
  searchAndFiltersApiUrl: string;
  joinPagesUrl: string;
  checkoutUrl: string;
  gtm: {
    gtmId: string;
    scriptId: string;
    dataLayerName: string;
    previewVariables: string;
    accountTypes: {
      agent: string;
      performer: string;
      castingDirector: string;
    };
  };
  rox: {
    key: string;
    namespace: string;
  };
  braze: {
    baseUrl: string;
    apiKey: string;
  };
  cookieScriptSrc: string;
  oidc: OidcConfig;
  isDev?: boolean;
};

export default {
  ...__CONFIG__,
};
