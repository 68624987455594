import { object, string } from "yup";
import { TFunction } from "i18n";

export function emailValidationSchema(t: TFunction, fieldName: string) {
  return object().shape({
    [fieldName]: string()
      .required(
        t("common:validation.required", {
          field: t("common:label.email"),
        })
      )
      .email(
        t("common:validation.notValid", {
          field: t("common:label.email"),
        })
      )
      .max(
        50,
        t("common:validation.maxLength", { field: "Email", length: "50" })
      ),
  });
}
