import React, { useEffect } from "react";
import { Head, Spinner } from "component-library";
import { useTranslation } from "i18n";
import CheckoutPageWrapper from "../CheckoutPageWrapper";
import { useCanAccessCheckout } from "component-library/src/components/Organisms/Billing/hooks/useCanAccessCheckout";
import { USER_ACCOUNT_APP_ROOT } from "component-library/src/components/Organisms/Billing/constants";
import { useHistory, useParams } from "react-router-dom";
import applicationMap, {
  isAcceptedCheckoutType,
} from "services/applicationMap";

const Checkout = () => {
  const { type } = useParams<{ type: string }>();
  const history = useHistory();

  useEffect(() => {
    if (!isAcceptedCheckoutType(type)) {
      return history.replace(applicationMap.routes.index());
    }
  }, [type]);

  const { t } = useTranslation();

  const { isLoading: canAccessCheckoutLoading } = useCanAccessCheckout(
    true,
    USER_ACCOUNT_APP_ROOT
  );

  if (canAccessCheckoutLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Head>
        <title>{t("common:billing.checkout.pageTitle")}</title>
      </Head>
      <CheckoutPageWrapper />
    </>
  );
};

export default Checkout;
