import React, { FC } from "react";
import cx from "classnames";
import DOMPurify from "dompurify";
import { Button } from "component-library";
import { ReactComponent as InfoIconSvg } from "component-library/src/images/svg/icons/info-icon.svg";
import { HtmlAnchorButton } from "pages/IndexPage/MyAccountPageUserMessaging";

type NotificationBannerProps = {
  messageHtml: string;
  buttons: ReadonlyArray<HtmlAnchorButton>;
  wrapperClassNames?: string;
  withButtons?: boolean;
};

export const notificationBannerButtonClassNames =
  "g-col-lg-3 c-my-account-page__notification-area__button";

const NotificationBanner: FC<NotificationBannerProps> = ({
  withButtons,
  buttons,
  children,
  wrapperClassNames,
  messageHtml,
}) => {
  const messageColumns = 12 - buttons.length * 3;

  return (
    <div
      className={cx("c-my-account-page__notification-area", wrapperClassNames)}
    >
      <div className="g-content-area">
        <div
          className={`g-col-lg-${messageColumns} c-my-account-page__notification-area__container`}
        >
          <InfoIconSvg className="mr-2" />
          <div
            className="c-my-account-page__notification-area__message"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(messageHtml),
            }}
          />
        </div>
        {children}
        {withButtons
          ? buttons.map((b) => (
              <div key={b.href} className={notificationBannerButtonClassNames}>
                <Button
                  type={b.type || "primary"}
                  newTab={b.target === "_blank"}
                  text={b.text}
                  onClick={b.onClick}
                  href={b.href}
                />
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default NotificationBanner;
