import { IBillingCommon } from "../../models/billing/common";

export const BillingCommonTranslations: IBillingCommon = {
  common: {
    subscriptionTitle: "1. Select subscription",
    billingDetailsTitle: "2. Enter billing details",
    paymentDetailsTitle: "3. Add payment details",
    securityDescription:
      "To increase the security of Internet transactions Visa and Mastercard introduced <a href='/'>3D-Secure</a> . Depending on the card you have chosed to use and the retailer from whom you are purchasing, when you click on Payment checkout you may be asked by your card issuer for further authentication. \n" +
      "Your transaction may take a few seconds to be authorised, please be patient.", // WSIWYG
    termsAndConditions:
      "By proceeding to checkout, you authorize Spotlight to charge the card you provide for the services you selected. Please read our <a href='/'>Terms & Conditions</a>, including our <a href='/'>Cancellation & Refund Policy</a> for more information. ", // WSIWYG
    back: "Back to My Account",
    backMobile: "Back To Subscriptions",
    submit: "Payment checkout",
    continueMobile: "Continue To Billing Details",
    threeDSPageHeader: "Payment authentication",
    showMoreBtnText: "Show More",
    confirm: "Confirm",
    cancel: "Cancel",
    close: "Close",
    ending: "Ending",
    change: "Change",
  },
  billingForm: {
    firstNameLabel: "First name:",
    lastNameLabel: "Last name:",
    address1Label: "Address 1:",
    address1TooltipHeader: "Address header",
    address1TooltipBody: "Enter the address where the card is registered",
    address2Label: "Address 2 (optional):",
    cityLabel: "City:",
    zipLabel: "Zip/Post code:",
    countryLabel: "Country:",
    stateProvinceLabel: "State/Province:",
    countryPlaceholder: "Select country",
    stateProvincePlaceholder: "Select State/Province",
    phoneLabel: "Mobile number:",
  },
  paymentDetailForm: {
    cardDetailTitle: "Card details:",
    cardDetailPlaceholder: "Card number",
    expDateTitle: "Exp Date:",
    expDateYearPlaceholder: "YY",
    expDateMonthPlaceholder: "MM",
    expDateTooltipHeader: "Tooltip header",
    expDateTooltipBody: "Please enter the long number in the front of the card",
    cvvTitle: "CVV:",
    cvvTooltipHeader: "Tooltip header",
    cvvTooltipBody:
      "Please enter the card details. CVV is the last 3 digits on the back of the card.",
    securityTitle: "Your transaction is secured with SSL encription.",
  },
  directDebitForm: {
    accountName: "Account holder name:",
    accountNameTooltipHeader: "Account holder name",
    accountNameTooltipBody: "This is the name of the bank account holder.",
    accountNumber: "Account number:",
    accountNumberTooltipHeader: "Account number",
    accountNumberTooltipBody: "This is the 8 digit bank account number",
    accountNumberConfirmation: "Re-enter account number:",
    accountNumberConfirmationTooltipHeader: "Re-enter account number",
    accountNumberConfirmationTooltipBody:
      "Please re enter your 8 digit bank account number",
    sortCode: "Sort code:",
    sortCodeTooltipHeader: "Sort code",
    sortCodeTooltipBody: "This is the 6 digit sort code",
    ibanLabel: "IBAN (International Bank Account Number):",
    ibanTooltipHeader: "IBAN",
    ibanTooltipBody:
      "Please enter the International Bank Account Number (IBAN).",
  },
  subscriptionText: {
    select: "Select",
    yourPlan: "Your plan",
    paymentCard: "Credit/Debit Card",
    vatPerUser: "VAT per user",
  },
  summary: {
    summaryTitle: "Order summary",
    subscriptionTypeTitle: "Subscription type",
    subscriptionTypeTooltipHeader: "Subscription type tooltip header",
    subscriptionTypeTooltipBody:
      "This is the subscription you are purchasing. If this is incorrect please change the selected plan in the Select subscription section above.",
    customerDetailTitle: "Customer details",
    customerDetailTooltipHeader: "Customer details tooltip header",
    customerDetailTooltipBody:
      "These are the details of who the subscription is for. If these are incorrect please do not continue and contact us.",
    nameLabel: "Name:",
    emailLabel: "Email:",
    phoneLabel: "Phone number:",
    discountTitle: "Apply discount code",
    discountTooltipHeader: "Discount tooltip header",
    discountTooltipBody:
      "If you have been given a discount code please enter it here. Note discount codes are case sensitive. Only one code allowed per purchase.",
    add: "Add",
    total: "TOTAL",
  },
  validation: {
    requiredField: "{fieldName} is required.",
    invalidField: "{fieldName} is not valid.",
    maxLength: "{fieldName} must be at most {length} characters.",
    invalidPhoneNumber: "Invalid phone number",
    fieldsDoNotMatch: "{fieldName} don't match!",
  },
  failedBanner: {
    failedBannerHeader: "Oh no! Unfortunately, you payment has failed.",
    failedBannerLink: "Please check your payment details and try again.",
  },
  directDebit: {
    directDebit: "Direct Debit",
    protectedBy: "Payments are protected by the",
    guarantee: "Direct Debit Guarantee",
    guaranteeText:
      "<p><span>The Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits.</span></p><p><span>If there are any changes to the amount, date or frequency of your Direct Debit, GC re Spotlight will notify you (normally 3 working days) in advance of your account being debited or as otherwise agreed. If you request GC re Spotlight to collect a payment, confirmation of the amount and date will be given to you at the time of the request.</span></p><p><span>If an error is made in the payment of your <strong>Direct Debit</strong>, by GC re Spotlight or your bank or building society, you are entitled to a full and immediate refund of the amount paid from your bank or building society.</span></p><p><span>If you receive a refund you are not entitled to, you must pay it back when GC re Spotlight asks you to.</span></p><p><span>You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify GC re Spotlight.</span></p>",
    authorisation: "Authorisation",
    confirmAuthBannerBody:
      "I confirm that I am the account holder and am authorised to set up Direct Debit payments on this account.",
    termsAndConditions:
      "By proceeding to checkout, you authorise Spotlight to set up your Direct Debit for the services you selected. Please read our <a target='_blank' href='https://www.spotlight.com/the-small-print/terms-conditions/'>Terms & Conditions</a>, which includes our Cancellation & Refund Policy for more information.",
    confirmHeader: "Please confirm your details",
    confirmDescription:
      "Your Direct Debit will be set up now, but we’ll confirm the amount and let you know before future payments are taken.",
    confirmName: "Name",
    confirmEmail: "Email",
    confirmAccountHolder: "Account holder name",
    confirmDirectDebitButton: "Set up this Direct Debit",
    confirmContactEmail: "accounts@spotlight.com",
    confirmPoweredBy: "Securely powered by",
    confirmLegal1:
      "By confirming you are agreeing to our <a target='_blank' href='https://www.spotlight.com/the-small-print/terms-conditions/'>Website Terms of Use</a>. GoCardless uses personal data as described in their <a href=''>Privacy Notice</a>. We use analytics <a href=''>cookies</a>.",
    confirmLegal2WithDDGuarantee:
      "You may cancel this Direct Debit at any time by contacting Spotlight or your bank. Your payments are protected by the",
    confirmLegal3: "Spotlight will appear on your bank statement.",
    confirmLegal4:
      "GoCardless (company registration number 07495895) is authorised by the Financial Conduct Authority under the Payment Services Regulations 2017, registration number 597190, for the provision of payment services.",
    directDebitPageAlert:
      "This page has timed out, please refresh the page which will take you back to your checkout.",
    sepaCreditorsNameLabel: "Creditors name",
    sepaCreditorsName: "Spotlight",
    sepaCreditorLabel: "Creditor",
    sepaCreditor: "DE56ATB00002197951",
    sepaAddressLabel: "Address",
    sepaAddress: "7 Leicester Place, London, WC2H 7RJ, UK",
    sepaTypeLabel: "Type",
    sepaType: "Recurrent",
    sepaReferenceLabel: "Reference",
    sepaReference: "Available after confirmation via email",
    sepaDateLabel: "Date",
    poweredByGoCardless:
      'Payments are securely powered <a target="_blank" href="https://gocardless.com/about/">GoCardless.</a>',
    sepaLegalParagraph1:
      "We will notify you via email at least 3 working days in advance of any changes to your payment date, frequency or amount.",
    sepaLegalParagraph2:
      "By signing this mandate form, you authorise (A) Spotlight to send instructions to your bank to debit your account (B) your bank to debit your account in accordance with the instructions from Spotlight.",
    sepaLegalParagraph3:
      "As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited.",
    sepaLegalParagraph4:
      "GoCardless SAS (company registration number 834 422 180, R.C.S. PARIS), is authorised by the ACPR (French Prudential Supervision and Resolution Authority), Bank Code (CIB) 17118, for the provision of payment services.",
  },
};
