import React, { FC } from "react";

interface IBannerMessageProps {
  title: string;
  description: JSX.Element | string;
}

const BannerMessage: FC<IBannerMessageProps> = ({
  title,
  description,
}: IBannerMessageProps) => {
  return (
    <div className="w-full px-3 border bg-grey-snow border-grey-vlight">
      <h6 className="text-[14px] font-bold">{title}</h6>
      <p className="text-[14px]">{description}</p>
    </div>
  );
};

export default BannerMessage;
